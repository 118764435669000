// const PROD = "https://afsg-api-dot-afsg-382716.uc.r.appspot.com";
const PROD = "https://afsg-api-dot-paradise-ranch-a4a3f.uc.r.appspot.com";
const DEV = "http://localhost:5000/";
const PROD_2 = "https://agile-falcon-server.vercel.app/";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
//export const BASE_URL = process.env.NODE_ENV === "development" ? DEV : PROD;

export const CLOUDINARY_URL =
  "https://api.cloudinary.com/v1_1/dxsowbvlv/image/upload";
