import { useEffect, useState } from "react";
import { Tabs, Row, Col, Modal, Spin, message, Tooltip } from "antd";
import {
  Container,
  ThemeButton,
  ThemeTable,
  ThemeDropdown,
  UserChats,
  ThemeInput,
  PersonalDetailsModal,
} from "../../components/components";
import {
  NavLink,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { left_arrow_icon, comment_icon } from "../../assets/icons/icons";
import { no_agenda } from "../../assets/images/images";
import EventAgenda from "../upcomingEvents/details/eventAgenda/eventAgenda";
import { useGetUsersQuery } from "../../services/auth";
import { useLazyGetAgendaQuery } from "../../services/agenda";
import {
  useGetInvitationQuery,
  usePutInvitationMutation,
} from "../../services/invitation";
import {
  MessageOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { getUserInfo } from "../../helpers/helpers";
import options from "../../lib/data/options.json";
import dayjs from "dayjs";
import {
  useDeleteMeetingsMutation,
  useGetMeetingsByUserIdQuery,
} from "../../services/newMeeting";
import {
  useAcceptInviteMutation,
  useGetAttendeeByIdQuery,
} from "../../services/slots";
import PersonalAgenda from "../upcomingEvents/details/personalAgenda/personalAgenda";
import { useGetEventByIDQuery } from "../../services/events";

const ProfileDetails = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { user_type, user_id, event_id } = useParams();
  const [selectedKey, setSelectedKey] = useState(
    searchParams.get("tab") || "1"
  );
  const { id } = useParams();

  const [day, setDay] = useState("Day 1");
  const [selectedStatusKey, setSelectedStatusKey] = useState("1");
  const { data, isLoading } = useGetUsersQuery({ userId: user_id });
  const [userProfile, setUserProfile] = useState({});
  const [getAgenda, { isLoading: agendaLoading }] = useLazyGetAgendaQuery();
  const [agenda, setAgenda] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [acceptedData, setAcceptedData] = useState([]);
  const [declinedData, setDeclinedData] = useState([]);
  const [status, setStatus] = useState("");
  const [table, setTable] = useState("");
  const [edit, setEdit] = useState(false);
  const [editID, setEditID] = useState("");
  const [editDetail, setEditDetail] = useState({});
  const [globalLoading, setGLobalLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [rejectedId, setRejectedIdId] = useState("");
  const [slotModal, setSlotModal] = useState(false);

  const [upcomingEvent, setUpcomingEvent] = useState({});
  const [meetingNotifications, setMeetingNotifications] = useState([]);
  const [userData, setUserData] = useState({});
  const [currentAttendees, setCurrentAttendees] = useState({});

  const [detailsModal, setDetailsModal] = useState(false);

  const [
    acceptInvite,
    { isLoading: inviteAcceptLoading, error: inviteAcceptError },
  ] = useAcceptInviteMutation();
  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation,
  } = useGetInvitationQuery({
    eventId: event_id,
    sender: user_id,
    receiver: user_id,
  });

  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError },
  ] = usePutInvitationMutation();

  const [
    deleteMeetings,
    { isLoading: deleteMeetingLoading, data: deleteData, isSuccess },
  ] = useDeleteMeetingsMutation();

  const [currentAttendee, setCurrentAttendee] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    data: atendeeData,
    isLoading: atendeeloading,
    isSuccess: isAtendeeSuccess,
  } = useGetAttendeeByIdQuery({ id: user_id, eventId: event_id });
  const { data: event, isLoading: eventLoading } =
    useGetEventByIDQuery(event_id);

  const {
    data: meetingsData,
    isLoading: meetingloading,
    refetch: refetchMeeting,
  } = useGetMeetingsByUserIdQuery(
    isAtendeeSuccess && { userId: atendeeData[0]?._id, eventId: event_id }
  );

  const sortMeetings = (meetings) => {
    return meetings.sort((a, b) => {
      if (a.slot && !b.slot) return -1;
      if (!a.slot && b.slot) return 1;
      return 0;
    });
  };
  const handleInviteAction = (data, action) => {
    if (action === "reject") {
      setRejectedIdId(data._id);
    } else {
      setLoadingId(data._id);
    }
    updateInvite(data, action);
  };

  const filterPending = sortMeetings(
    meetingsData?.meetings?.filter(
      (item) =>
        item.status === "pending" &&
        (item.receiver._id === atendeeData[0]?._id ||
          item.sender._id === atendeeData[0]?._id)
    ) || []
  );

  const [meetingToDelete, setMeetingToDelete] = useState(null);

  const showDeleteConfirmation = (meetingId) => {
    setMeetingToDelete(meetingId);
    setDeleteModalVisible(true);
  };

  const handleDeleteMeeting = async () => {
    try {
      await deleteMeetings(meetingToDelete);
      message.success("Meeting deleted successfully");
      refetchMeeting();
    } catch (error) {
      console.error("Error deleting meeting:", error);
      message.error("An error occurred while deleting the meeting");
    } finally {
      setDeleteModalVisible(false);
      setMeetingToDelete(null);
    }
  };

  const filterAccepted = sortMeetings(
    meetingsData?.meetings?.filter((item) => item.status === "accepted") || []
  );

  const filterRejected = sortMeetings(
    meetingsData?.meetings?.filter((item) => item.status === "rejected") || []
  );

  const updateInvite = async (data, action) => {
    try {
      if (!data?._id) {
        message.error("Please ensure the invitation details are available.");
        return;
      }

      const inviteData = {
        meetingId: data._id,
        isRejected: action === "reject",
      };

      const invite = await acceptInvite(inviteData);

      console.log(invite, "inviteResponse");

      if (invite?.error) {
        message.error(
          invite.error.data?.error || `Failed to ${action} the invite.`
        );
        return;
      }
      refetchMeeting();

      setSlotModal(false); // Close the modal after a successful update
      message.success(`Invite ${action}ed successfully`);
    } catch (err) {
      console.error(`Error ${action}ing invite:`, err);
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  const columns = [
    {
      title: "Meeting",
      dataIndex: "meeting",
      key: "meeting",
      render: (text, data) =>
        data?.receiver?.user._id === getUserInfo().id ? (
          <p className="text-center font-medium">
            <NavLink
              onClick={() => {
                setDetailsModal(true);
                setUserData(data?.sender || data?.receiver);
              }}
              className="font-bold underline hover:!text-primary"
            >
              {data?.sender.user?.first_name} {data?.sender.user?.last_name}{" "}
            </NavLink>
            is requesting a meeting with you
          </p>
        ) : (
          <p className="text-center font-medium">
            You requested a meeting with{" "}
            <NavLink
              onClick={() => {
                setDetailsModal(true);
                setUserData(data?.receiver || data?.sender);
              }}
              className="font-bold underline hover:!text-primary"
            >
              {data?.receiver.user?.first_name} {data?.receiver.user?.last_name}{" "}
            </NavLink>
          </p>
        ),
    },
    {
      title: "Meeting Time",
      dataIndex: "meetingtime",
      key: "meetingtime",
      width: "20%",
      render: (text, data) => (
        <p className="text-center font-medium">
          {data?.slot?.startTime
            ? `${data?.slot?.startTime} - ${data?.slot?.endTime} (${data?.slot?.day})`
            : "----"}
        </p>
      ),
    },
    {
      title: "Table Number",
      dataIndex: "table_number",
      key: "table_number",
      width: "15%",
      render: (text, data) => {
        const tableNumber =
          data?.sender?.user?.metadata?.atendee_details?.table_number ||
          data?.receiver?.user?.metadata?.atendee_details?.table_number;

        return <p className="text-center font-medium">{tableNumber || "-"}</p>;
      },
    },

    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "5%",
      render: (text, data) => (
        <p className="text-center font-medium">
          <NavLink
            to={`/chats?user=${
              data?.sender.user?._id === getUserInfo().id
                ? data?.receiver.user?._id
                : data?.sender.user?._id
            }&id=${id}`}
          >
            <Tooltip title={data.note} color={"#e2b025"}>
              <MessageOutlined style={{ fontSize: 20, cursor: "pointer" }} />
            </Tooltip>
          </NavLink>
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "30%",
      render: (text, data) => (
        <div className="flex justify-center flex-col md:flex-row">
          {data?.receiver.user._id === getUserInfo().id &&
          data.status === "pending" ? (
            <div
              className="flex justify-center flex-col md:flex-row"
              onClick={() => setCurrentAttendees(data)}
            >
              <ThemeButton
                onClick={() => handleInviteAction(data, "accept")}
                className={"ml-4 w-35 !bg-themeGreen"}
                content={
                  (loadingId === data._id && inviteAcceptLoading) ||
                  meetingloading ? (
                    <Spin className="pl-7 pr-7" size="small" />
                  ) : (
                    "Accept"
                  )
                }
                disable={
                  (loadingId === data._id && inviteAcceptLoading) ||
                  meetingloading
                }
              />
              <ThemeButton
                className={"ml-4 w-35 !bg-themeRed"}
                content={
                  (rejectedId === data._id && inviteAcceptLoading) ||
                  meetingloading ? (
                    <Spin className="pl-7 pr-7" size="small" />
                  ) : (
                    "Reject"
                  )
                }
                disable={
                  (rejectedId === data._id && inviteAcceptLoading) ||
                  meetingloading
                }
                onClick={() => handleInviteAction(data, "reject")}
              />
            </div>
          ) : (
            <div className="flex items-center">
              <div
                className={`h-9 w-24 md:w-32 ${
                  data.sender.user._id === getUserInfo().id &&
                  data.status === "pending"
                    ? "bg-primary"
                    : data.status === "accepted"
                    ? "!bg-themeGreen"
                    : data.status === "rejected"
                    ? "!bg-themeRed"
                    : "bg-primary"
                } rounded-lg flex justify-center items-center mr-2`}
              >
                <p className="font-semibold text-white capitalize">
                  {data.sender.user._id === getUserInfo().id &&
                  data.status === "pending"
                    ? "Pending"
                    : data.status}
                </p>
              </div>
              <ThemeButton
                className={"w-10 h-9 !bg-themeRed"}
                content={<DeleteOutlined />}
                onClick={() => showDeleteConfirmation(data._id)}
                disable={deleteMeetingLoading}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const updateMeeting = async () => {
    console.log("editDetail", editDetail);
    const invite = await putInvitation({
      id: editID,
      body: {
        event: editDetail.event,
        status: status,
        table: table ? table : null,
        event: editDetail.event,
        sender: editDetail.sender._id,
        receiver: editDetail.receiver._id,
        isSenderSponser: editDetail.sender.user_type === "sponsor",
        updateTable: status === "accepted",
      },
    });
    setStatus("");
    setTable("");
    setEditID("");
    setEdit(false);
    refetchInvitation();
  };

  const getUserAgenda = async () => {
    const response = await getAgenda({
      event_id: event_id,
      user_id: user_id,
      day,
    });
    const arr = response?.data?.agendas.data || [];
    setAgenda(arr.map((v) => v.schedule));
  };

  function sortObjectsByDayAndTime(objects) {
    // Define a custom sorting function
    function compareDayAndTime(a, b) {
      const dayA = a.meeting.day;
      const dayB = b.meeting.day;

      if (dayA === dayB) {
        const timeFormat = "hh:mm A"; // Specify the time format
        const timeObjA = dayjs(a.meeting.start_time, timeFormat);
        const timeObjB = dayjs(b.meeting.start_time, timeFormat);

        return timeObjA - timeObjB;
      }

      return dayA.localeCompare(dayB);
    }

    // Sort the objects using the custom sorting function
    const sortedObjects = objects.slice().sort(compareDayAndTime);

    return sortedObjects;
  }

  useEffect(() => {
    setUpcomingEvent(event?.events?.data[0]);
  }, []);

  useEffect(() => {
    getUserAgenda();
  }, [day]);

  const navigate = useNavigate();
  useEffect(() => {
    setUserProfile(data?.users?.data[0] || {});
  }, [isLoading]);
  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };
  const onChangeStatus = (key) => {
    setSelectedStatusKey(key);
  };
  const statusItems = [
    {
      key: "1",
      label: (
        <p className={`${selectedStatusKey === "1" && "font-semibold"}`}>
          Pending
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterPending} />,
    },
    {
      key: "2",
      label: (
        <p className={`${selectedStatusKey === "2" && "font-semibold"}`}>
          Accepted
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterAccepted} />,
    },
    {
      key: "3",
      label: (
        <p className={`${selectedStatusKey === "3" && "font-semibold"}`}>
          Rejected
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterRejected} />,
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>
          Personalized Agenda
        </p>
      ),
      children: (
        <>
          <PersonalAgenda
            eventId={event_id}
            schedules={event?.events?.data[0]?.schedules || []}
            isOtherNetworking={true}
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <p className={`${selectedKey === "2" && "font-semibold"}`}>
          Meeting Iternary
        </p>
      ),
      children: (
        <>
          <Row className="bg-white rounded-xl p-5">
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className="flex flex-col items-center mt-3 lg:mt-0"
            >
              <p className="font-semibold text-base md:text-lg">
                Total Invites
              </p>
              <div className="bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                <p className="font-bold text-white text-base">
                  {filterPending?.length +
                    filterAccepted?.length +
                    filterRejected?.length}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className="flex flex-col items-center"
            >
              <p className="font-semibold text-base md:text-lg">
                Pending Invites
              </p>
              <div className="bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                <p className="font-bold text-white text-base">
                  {filterPending?.length || 0}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className="flex flex-col items-center mt-3 md:mt-0"
            >
              <p className="font-semibold text-base md:text-lg">
                Accepted Invites
              </p>
              <div className="bg-themeGreen w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                <p className="font-bold text-white text-base">
                  {filterAccepted?.length || 0}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className="flex flex-col items-center mt-3 lg:mt-0"
            >
              <p className="font-semibold text-base md:text-lg">
                Rejected Invites
              </p>
              <div className="bg-themeRed w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                <p className="font-bold text-white text-base">
                  {filterRejected?.length || 0}
                </p>
              </div>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="1"
            items={statusItems}
            onChange={onChangeStatus}
            className="mt-5"
          />
        </>
      ),
    },
    {
      key: "3",
      label: "Chats",
      children: <UserChats userID={user_id} eventID={event_id} chatList={[]} />,
    },
  ];
  const menuSelected = {
    sponsors: "3",
    delegates: "4",
  };
  return (
    <>
      <PersonalDetailsModal
        userData={userData}
        isModalOpen={detailsModal}
        onClose={() => setDetailsModal(false)}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteMeeting}
        onCancel={() => setDeleteModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this meeting?</p>
      </Modal>

      <Container
        selected={menuSelected[user_type]}
        header={
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <NavLink to={`/event/${event_id}`}>
                <img src={left_arrow_icon} width={18} height={18} alt="" />
              </NavLink>
              <p className="ml-3 font-semibold text-2xl">Back</p>
            </div>
            <div className="flex">
              {selectedKey === "2" && (
                <ThemeButton
                  onClick={() => navigate("/chats")}
                  content={<img alt="" src={comment_icon} width={20} />}
                />
              )}
              <ThemeButton
                content={"Download Agenda"}
                className="ml-3 hidden"
              />
            </div>
          </div>
        }
      >
        <p className="font-bold text-3xl">
          {userProfile?.first_name} {userProfile?.last_name} (
          {userProfile.user_type})
        </p>
        <p className="text-sm font-semibold">
          {userProfile?.metadata?.delegate_details?.position || "---"}
        </p>
        <p className="text-xs font-semibold">{userProfile?.email}</p>
        <p className="text-xs font-semibold">
          {userProfile?.metadata?.delegate_details?.phone}
        </p>
        <Tabs
          defaultActiveKey={searchParams.get("tab") || "1"}
          items={items}
          onChange={onChange}
          className="mt-5"
        />
        <Modal
          footer={false}
          onCancel={() => setEdit(false)}
          open={edit}
          width={500}
        >
          <div className="p-6">
            <div className="w-100">
              <ThemeDropdown
                label={"Meeting status"}
                styles={{ width: "100%", height: 40 }}
                setValue={(e) => setStatus(e)}
                text={status}
                labelPrimary
                items={[
                  {
                    label: "Invited",
                    key: "invited",
                    value: "invited",
                  },
                  {
                    label: "Accepted",
                    key: "accepted",
                    value: "accepted",
                  },
                  {
                    label: "Rejected",
                    key: "rejected",
                    value: "rejected",
                  },
                ]}
              />
            </div>
            <div className="mt-4">
              <ThemeInput
                label={"Table Number"}
                placeholder={"table number"}
                labelPrimary
                value={table}
                onChange={(e) => setTable(e.target.value)}
              />
            </div>
            <div className="w-100 mt-4">
              <ThemeButton
                onClick={updateMeeting}
                content={"Update"}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default ProfileDetails;
