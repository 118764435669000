import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const MeetingsApi = createApi({
  reducerPath: "meetings",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMeetings: builder.query({
      query: ({ scheduleID, eventID }) => ({
        url: "api/meeting",
        method: "GET",
        params: {
          schedule_id: scheduleID || "",
          event_id: eventID || "",
          result_per_page : 20
        },
      }),
    }),
    putMeetings: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/meeting/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    putMultipleMeetings: builder.mutation({
      query: (body) => {
        return {
          url: `api/meeting/update/multiple`,
          method: "PUT",
          body: body,
        };
      },
    }),
    addMeetings: builder.mutation({
      query: (body) => ({
        url: `api/meeting`,
        method: "POST",
        body: body,
      }),
    }),
    deleteMultipleMeetings: builder.mutation({
      query: (body) => ({
        url: `api/meeting/delete/multiple`,
        method: "DELETE",
        body: body,
      }),
    }),
  }),
});

export const {
  useAddMeetingsMutation,
  usePutMeetingsMutation,
  usePutMultipleMeetingsMutation,
  useDeleteMultipleMeetingsMutation,
  useGetMeetingsQuery,
  useLazyGetMeetingsQuery,
} = MeetingsApi;
