import React, { useState } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import MonaSansRegular from '../../assets/fonts/Mona-Sans-Regular.ttf';
import MonaSansBold from '../../assets/fonts/Mona-Sans-Bold.ttf';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { calcLength } from "framer-motion";

dayjs.extend(customParseFormat);

const PersonalAgendaPDF = ({
  agenda,
  userInfo,
  day,
  date,
  networkingSessions,
  schedules,
  meetings,
  currentUserId,
  eventDate
}) => {

  const day1 = "Day 1"
  const day2 = "Day 2"
  const [isMap, setIsMap] = useState(true)
  console.log(userInfo, "userInfo")


  if (!schedules) return null

  const parsedDate = dayjs(date, 'MMMM Do, YYYY');
  const nextDate = parsedDate.add(1, 'day');

  console.log(nextDate.format('MMMM Do, YYYY'), "nextDate")

  console.log(currentUserId, "currentUserId")
  console.log(schedules, "schedules")
  console.log(day, "daydayday")
  console.log(date, "date")
  console.log(meetings, "meetingsNewsndsd")




  // Sort function for schedule items
  const sortScheduleByStartTime = (a, b) => {
    const timeA = dayjs(a.start_time, "hh:mm A");
    const timeB = dayjs(b.start_time, "hh:mm A");
    return timeA.diff(timeB);
  };


  const filterDay1 = schedules.filter(item => item.day === day1)
  const filterDay2 = schedules.filter(item => item.day === day2)
  // Sort the schedules array
  const sortedSchedulesDay1 = filterDay1.sort(sortScheduleByStartTime);
  const sortedSchedulesDay2 = filterDay2.sort(sortScheduleByStartTime);

  const sortedSchedules = [...sortedSchedulesDay1, ...sortedSchedulesDay2]
  let constructAgenda1 = [];
  let otherEvents = [];
  let setDay2 = true;


  const includesTypes = ["sponsor-presentation", "panel-discussion", "keynote", "registration"];

  const formatDate = (date) => {
    return date.format('MMMM D[th], YYYY').replace(/(\d)(th|st|nd|rd)/, (match, d, suffix) => {
      if (d > 3 && d < 21) return `${d}th`;
      switch (d % 10) {
        case 1: return `${d}st`;
        case 2: return `${d}nd`;
        case 3: return `${d}rd`;
        default: return `${d}th`;
      }
    });
  };

  const startDate = dayjs(eventDate?.start_date);
  const day2Date = startDate.add(1, 'day');

  for (let i = 0; i < sortedSchedules.length; i++) {
    const currentEvent = sortedSchedules[i];

    if (currentEvent.day === day2 && setDay2) {
      setDay2 = false;
      if (otherEvents.length > 0) {
        constructAgenda1.push({ type: "other", events: otherEvents });
        otherEvents = [];
      }
      constructAgenda1.push({ type: "dayEnd", day: "END OF DAY 1" });
      constructAgenda1.push({ type: "day", day: "Day 2", date: formatDate(day2Date) });
    }

    if (includesTypes.some(type => currentEvent.type.includes(type))) {
      otherEvents.push(currentEvent);
    } else {
      if (otherEvents.length > 0) {
        constructAgenda1.push({ type: "other", events: otherEvents });
        otherEvents = [];
      }
      constructAgenda1.push(currentEvent);
    }
  }

  // Handle any remaining events after the loop
  if (otherEvents.length > 0) {
    constructAgenda1.push({ type: "other", events: otherEvents });
  }


  // Add Day 1 at the beginning and END OF DAY 2 at the end
  constructAgenda1.unshift({ type: "day", day: "Day 1", date: formatDate(startDate) });
  constructAgenda1.push({ type: "dayEnd", day: "END OF DAY 2" });

  console.log(constructAgenda1, "constructAgenda1");

  console.log(schedules, "schedules")
  console.log(sortedSchedules, "sortedSchedules")
  // const day2Meetings = meetings?.filter(meeting => meeting?.slot?.day === day2)
  // const day1Meetings = meetings?.filter(meeting => meeting?.slot?.day === day1)

  const AgendaTable = ({ items }) => {

    console.log(items, "itemsFormas")
    if (!items) return null;
    return (
      items.map((item, index) => {

        const activity1 = item?.type?.toString()?.split("-")?.[0] || "";
        const activity2 = item?.type?.toString()?.split("-")?.[1] || "";
        const mainActivity = item?.short_title || activity1 + " " + activity2;

        return (
          <View style={[styles.tableRow]} wrap={false} key={index} >
            <Text style={[styles.tableHeaderCell, styles.timeCell]} wrap={false}>
              {item.start_time} - {item.end_time}
            </Text>
            <Text style={[styles.tableHeaderCell, styles.locationCell]} wrap={false}>
              {mainActivity?.includes("registration")
                ? "Registration Desk"
                : "Presentation Room"}
            </Text>
            <Text style={[styles.tableHeaderCell, styles.activityCell]} wrap={false}>
              {mainActivity || ""}
            </Text>
            {item?.type?.includes("panel") ? <View style={[styles.tableHeaderCell, styles.descriptionCellForMeetings]}>
              <Text style={styles.textLeverage}>{item?.title}</Text>
              {item?.panellist?.map((innerItem, index2) => {
                return (
                  <View style={styles.attendeeInfo} key={index2} wrap={false}>
                    <View style={styles.avatarContainer} wrap={false}>
                      <Image
                        src={innerItem?.picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                        style={styles.avatarImage}
                      />
                    </View>
                    <Text style={styles.attendeeName} wrap={false}>
                      {innerItem?.first_name + " | " + innerItem?.metadata?.delegate_details?.company}
                    </Text>
                  </View>
                )
              })}
              {item.moderator && <View style={styles.attendeeInfo} wrap={false}>
                <View style={styles.avatarContainer} wrap={false}>
                  <Image
                    src={item.moderator?.picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                    style={styles.avatarImage}
                  />
                </View>
                <Text style={styles.attendeeName} wrap={false}>
                  {item.moderator?.first_name + " | " + item.moderator?.metadata?.delegate_details?.company}
                </Text>
              </View>}

            </View> : <Text style={[styles.tableHeaderCell, styles.descriptionCell]} wrap={false} >
              {item?.title || ""}
            </Text>}
          </View>
        )
      })
    )
  }

  const NetworkingSessionsTable = ({ slots, day1Meetings }) => {
    return (

      slots?.map((v, index) => {
        const filterSLotsForPersonalized = day1Meetings.filter(
          (item) => item?.slot?._id === v._id
        );

        if (filterSLotsForPersonalized?.length === 0) return null;

        return filterSLotsForPersonalized?.map((item, index2) => {
          const isCurrentUserSender = item?.sender?.user?._id === currentUserId;
          const otherParty = isCurrentUserSender ? item?.receiver?.user : item?.sender?.user;

          const meetingIndex = index * filterSLotsForPersonalized.length + index2 + 1;

          return (
            <View style={[styles.tableRow]} key={`${index}-${index2}`} wrap={false}>
              <Text style={[styles.tableHeaderCell, styles.timeCell]} wrap={false}>
                {item?.slot?.startTime} - {item?.slot?.endTime}
              </Text>
              <Text style={[styles.tableHeaderCell, styles.locationCell]} wrap={false}>
                Pre Meeting Area
              </Text>
              <Text style={[styles.tableHeaderCell, styles.activityCell]} wrap={false}>
                {`Meeting ${meetingIndex}`}
              </Text>
              <Text style={[styles.tableHeaderCell, styles.tableNewCell]} wrap={false}>
                {item?.sender?.user?.metadata?.atendee_details?.table_number || "--------"}
              </Text>
              <View style={[styles.tableHeaderCell, styles.descriptionCellForMeetings2]} wrap={false}>
                <View style={styles.attendeeInfo} wrap={false}>
                  <View style={styles.avatarContainer} wrap={false}>
                    <Image
                      src={otherParty.picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                      style={styles.avatarImage}
                    />
                  </View>
                  <Text style={styles.attendeeName} wrap={false}>
                    {otherParty?.first_name + " | " + otherParty?.metadata?.delegate_details?.company}
                  </Text>
                </View>
              </View>
            </View>
          );
        });
      })

    );
  };


  const renderAgendaItem = (item, data) => {
    console.log(item, "item")
    console.log(data, "dataOFMapper")
    switch (item) {
      case "day":
        return (
          <View style={styles.dayHeader} wrap={false}>
            <Text style={styles.dayText}>{data.day}</Text>
            <Text style={styles.dateText}>{data.date}</Text>
          </View>
        )
      case "dayEnd":
        return (
          <View style={styles.networkingHeader} wrap={false}>
            <Text style={styles.networkingHeaderText}>{data.day}</Text>
          </View>
        )
      case "break":
        return (
          <View style={styles.coffeeBreak} wrap={false}>
            <Text style={styles.coffeeBreakText}>{data.short_title || data.title}</Text>
            <Text style={styles.coffeeBreakTime}>{data.start_time} - {data.end_time}</Text>
          </View>
        );
      case "networking":
        return (
          <>

            {(() => {
              const hasAnyMeetings = data.slots.some(v =>
                meetings.some(item => item?.slot?._id === v._id)
              );

              if (!hasAnyMeetings) return null;

              return (
                <>
                  <View style={styles.networkingHeaderTable} wrap={false}>
                    <Text style={styles.networkingHeaderText} wrap={false}>Networking Session</Text>
                  </View>
                  <View style={styles.tableNetworking}>
                    <View style={[styles.tableRow]} wrap={false}>
                      <Text style={[styles.tableCell, styles.timeCell]}>
                        <Text style={styles.newTime}>Time</Text>
                      </Text>
                      <Text style={[styles.tableCell, styles.locationCell]}>
                        Location
                      </Text>
                      <Text style={[styles.tableCell, styles.activityCell]}>
                        Activity
                      </Text>
                      <Text style={[styles.tableCell, styles.tableNewCell]}>
                        Table No
                      </Text>
                      <Text style={[styles.tableCell, styles.descriptionCellHeader2]}>
                        Attendees Name
                      </Text>
                    </View>
                    <NetworkingSessionsTable slots={data.slots} day1Meetings={meetings} />
                  </View>
                </>
              );
            })()}
          </>
        );
      default:
        return <>
          <View style={styles.table} >
            <View style={[styles.tableRow]} wrap={false}>
              <Text style={[styles.tableCell, styles.timeCell]}>
                <Text style={styles.newTime}>Time</Text>
              </Text>
              <Text style={[styles.tableCell, styles.locationCell]}>Location</Text>
              <Text style={[styles.tableCell, styles.activityCell]}>Activity</Text>
              <Text style={[styles.tableCell, styles.descriptionCellHeader]}>
                Description
              </Text>
            </View>
            <AgendaTable items={data.events} />
          </View>
        </>;
    }
  };



  return (
    <>
      <Document>
        <Page style={styles.page}>

          <View style={styles.header}>
            <View style={styles.nameBox}>
              <Text style={styles.nameText}>{userInfo.name}</Text>
            </View>
            <View style={styles.infoContainer}>
              <Text style={styles.positionText}>{userInfo.position}</Text>
              <Text style={styles.companyText}>{userInfo.company}</Text>
            </View>
          </View>

          {constructAgenda1?.map((item, index) => {

            let MapperItem;
            let data;

            if (item.type === "networking" || item.type.includes("other_networking")) {
              MapperItem = "networking";
              data = item
            } else if (item.type.includes("coffe-break") || item.type.includes("lunch-break") || item.type.includes("cocktail-break")) {
              MapperItem = "break";
              data = item
            } else if (item.type === "day") {
              MapperItem = "day";
              data = item
            } else if (item.type === "dayEnd") {
              MapperItem = "dayEnd";
              data = item
            } else {
              data = item
            }

            return (
              <React.Fragment key={index}>
                {renderAgendaItem(MapperItem, data)}
              </React.Fragment>
            )
          })}

        </Page>
      </Document>
    </>
  )
};

export default PersonalAgendaPDF;



Font.register({
  family: 'Mona-Sans',
  fonts: [
    { src: MonaSansRegular, fontWeight: 'normal' },
    { src: MonaSansBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "100%",
    marginVertical: 20,

  },
  header: {
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    fontFamily: "Mona-Sans",
    gap: 20
  },
  nameBox: {
    backgroundColor: "#C6911A",
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginLeft: 50,
    fontFamily: "Mona-Sans",


  },
  nameText: {
    color: "#ffffff",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: "Mona-Sans"

  },
  infoContainer: {
    marginRight: 10,
    fontFamily: "Mona-Sans"

  },
  positionText: {
    fontSize: 15,
    marginBottom: 2,
    maxWidth: "350px",
  },
  companyText: {
    fontSize: 13,
    fontWeight: "bold",
  },
  dayHeader: {
    backgroundColor: "#C6911A",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    fontFamily: "Mona-Sans"
  },
  dayText: {
    color: "#ffffff",
    fontSize: 30,
    fontWeight: "bold",
    paddingLeft: 30,
  },
  dateText: {
    color: "#ffffff",
    fontSize: 16,
    fontFamily: "Mona-Sans",
    fontWeight: "medium"
  },
  coffeeBreak: {
    backgroundColor: "#C6911A",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 30,
    paddingLeft: 40,
    fontFamily: "Mona-Sans"

  },
  coffeeBreakNewww: {
    backgroundColor: "#C6911A",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 20,
    paddingLeft: 40,
    marginTop: 20
  },
  coffeeBreakText: {
    color: "#ffffff",
    fontSize: 20,
    fontWeight: "bold",
  },
  coffeeBreakTime: {
    color: "#ffffff",
    fontSize: 15,
    fontFamily: "Mona-Sans",
    fontWeight: "medium"
  },
  table: {
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    overflow: "hidden",
    marginTop: 10,
    marginHorizontal: 50,
    borderCollapse: "collapse",
  },
  tableNetworking: {
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    overflow: "hidden",
    marginTop: 20,
    marginHorizontal: 50,
    borderCollapse: "collapse"
  },

  paddingtop: {
    marginTop: 90,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #E0E0E0",
    // marginTop: 10,
  },
  tableHeader: {
    backgroundColor: "#fff",
  },
  tableHeaderCell: {
    color: "#000",
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,

  },

  tableNewCell: {
    width: "15%", borderRight: "1px solid #E0E0E0",

  },

  tableCell: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid #E0E0E0",
    paddingTop: 10,
    paddingBottom: 8,
    backgroundColor: "#E6BB5A",
  },

  tableHeaderCellNew: {
    height: "100%",
    width: "80%",
  },
  backgroundImage: {
    backgroundColor: "#C6911A",
    padding: 20
  },
  descriptionCellHeader: {
    width: "40%",
    fontSize: 12,
    paddingHorizontal: 20,
    border: "none"
  },

  descriptionCellHeader2: {
    width: "50%",
    fontSize: 12,
    paddingHorizontal: 20,
    border: "none"
  },
  childDesc: {
    padding: 20,
    backgroundColor: "#E6BB5A",
    display: "block",
  },

  timeCell: {
    width: "25%",
    borderRight: "1px solid #E0E0E0",

  },
  locationCell: {
    width: "25%", borderRight: "1px solid #E0E0E0",

  },
  activityCell: {
    width: "20%",
    borderRight: "1px solid #E0E0E0",
    textTransform: "capitalize",
  },
  activityCell2: {
    width: "10%",
    borderRight: "1px solid #E0E0E0",
    textTransform: "capitalize",
  },
  descriptionCell: {
    width: "40%",
    fontSize: 8,
    paddingHorizontal: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

  },
  descriptionCellForMeetings: {
    width: "40%",
    fontSize: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingHorizontal: 3
  },
  descriptionCellForMeetings2: {
    width: "50%",
    fontSize: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingHorizontal: 3
  },
  textLeverage: {
    width: "100%",
    backgroundColor: "#C6911A",
    color: "#ffffff",
    fontSize: 8,
    fontWeight: "medium",
    padding: 5,
    borderRadius: 2,
    marginBottom: 2
  },
  attendeeInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3
  },
  avatarContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: '#C6911A',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,

  },

  avatarImage: {
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  avatarText: {
    color: '#ffffff',
    fontSize: 10,
    fontWeight: 'bold',

  },
  attendeeName: {
    fontSize: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  descriptionCellParent: {
    fontSize: 5,
    width: "38%",
    marginLeft: 5,
    marginRight: 5,
    paddingVertical: 3

  },

  descText: {
    backgroundColor: "#E6BB5A",
    color: "#ffff",
    textAlign: "left",

  },
  speakerText: {
    fontSize: 10,
    marginBottom: 2,
  },
  panelHeader: {
    backgroundColor: "#C6911A",
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  panelHeaderText: {
    color: "#ffffff",
    fontSize: 10,
    fontWeight: "bold",
  },
  panelSpeaker: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
    position: "relative",
    fontFamily: "Mona-Sans"

  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 8,
  },
  panelSpeakerText: {
    fontSize: 8,
  },
  networkingHeader: {
    backgroundColor: "#C6911A",
    paddingVertical: 18,
    paddingHorizontal: 20,
    textAlign: "center",
    marginVertical: 20,
    marginTop: 50,
  },
  networkingHeaderTable: {
    backgroundColor: "#C6911A",
    paddingVertical: 18,
    paddingHorizontal: 20,
    textAlign: "center",
    marginVertical: 20,
    // marginTop: 60,
  },
  networkingHeader2: {
    marginTop: 20,
  },
  networkingHeaderText: {
    color: "#ffffff",
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "Mona-Sans"

  },
  cocktailParty: {
    backgroundColor: "#C6911A",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  cocktailPartyText: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "bold",
  },
  cocktailPartyTime: {
    color: "#ffffff",
    fontSize: 12,
  },
  endOfDay: {
    backgroundColor: "#C6911A",
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginBottom: 20,
  },
  endOfDayText: {
    color: "#ffffff",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  descriptionText: {
    fontSize: 10,
    marginBottom: 4,
  },

});








