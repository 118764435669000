import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const FeedbackApi = createApi({
  reducerPath: "feedback",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFeedback: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000, eventId = "" }) => ({
        url: "api/feedback",
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
          event_id: eventId,
        },
      }),
    }),
  }),
});

export const { useGetFeedbackQuery } = FeedbackApi;
