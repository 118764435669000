import { ConfigProvider } from "antd";
import AppRouter from "./config/router/router";
import Montserrat from "./assets/fonts/Montserrat.ttf";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./Chakratheme.js";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#E2B024",
              fontFamily: Montserrat,
            },
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <AppRouter />
          </DndProvider>
        </ConfigProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
