import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken } from "../helpers/helpers";

// Create the Slots API
export const SlotsApi = createApi({
  reducerPath: "slots",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  tagTypes: ["Slot"], // Define the tag type
  endpoints: (builder) => ({
    // Create a new slot
    createSlot: builder.mutation({
      query: (body) => ({
        url: "api/slots",
        method: "POST",
        body: body?.slots,
      }),
      // Invalidate the Slot tag to refetch related queries
      invalidatesTags: ["Slot"],
    }),

    // Send an invite
    sendInvite: builder.mutation({
      query: (body) => ({
        url: "api/slots/invite",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Slot"],
    }),

    // Accept an invite
    acceptInvite: builder.mutation({
      query: (body) => ({
        url: "api/slots/invite-accept",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Slot"],
    }),

    // Get a slot by ID
    getSlotById: builder.query({
      query: (id) => ({
        url: `api/slots/get-by-id/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Slot", id }],
    }),

    getAttendeeById: builder.query({
      query: ({ id, eventId }) => ({
        url: `api/slots/get-attendee`,
        method: "GET",
        params: {
          id: id,
          eventId: eventId,
        },
      }),
    }),

    // Get all slots
    getAllSlots: builder.query({
      query: (data) => ({
        url: "api/slots/get-all",
        method: "GET",
        params: data,
      }),
      providesTags: ["Slot"], // This will refetch when a Slot is invalidated
    }),

    // Update a slot by ID
    updateSlotOne: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/slots/update-one/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Slot", id }],
    }),
    updateSlotMany: builder.mutation({
      query: ({ slots }) => ({
        url: `api/slots/update-many`,
        method: "PUT",
        body: { slots: [...slots] },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Slot", id }],
    }),

    // Delete a slot by ID
    deleteSlot: builder.mutation({
      query: (id) => ({
        url: `api/slots/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Slot", id }],
    }),
    deleteSlotAll: builder.mutation({
      query: ({ ids }) => ({
        url: `api/slots/delete-all`,
        method: "DELETE",
        body: { ids: [...ids] },
      }),
      invalidatesTags: (result, error, id) => [{ type: "Slot", id }],
    }),

    // Get slots by type and ensure no meetings are present
    getSlotsByTypeAndNoMeetings: builder.query({
      query: ({ meetingType, eventId }) => ({
        url: `api/slots/getByType`,
        method: "GET",
        params: {
          meetingType,
          eventId,
          // senderId,
          // receiverId,
        },
      }),
      providesTags: ["Slot"],
    }),
  }),
});

// Export hooks for using the queries/mutations
export const {
  useCreateSlotMutation,
  useSendInviteMutation,
  useAcceptInviteMutation,
  useGetSlotByIdQuery,
  useGetAttendeeByIdQuery,
  useGetAllSlotsQuery,
  useUpdateSlotOneMutation,
  useUpdateSlotManyMutation,
  useDeleteSlotMutation,
  useDeleteSlotAllMutation,
  useGetSlotsByTypeAndNoMeetingsQuery,
} = SlotsApi;
