import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const SchedulesApi = createApi({
  reducerPath: "schedule",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSchedules: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        type,
        hall,
        day,
        eventID,
      }) => ({
        url: "api/schedule",
        method: "GET",
        params: {
          current_page: currentPage || 1,
          result_per_page: resultPerPage || 10,
          type: type || "",
          hall: hall || "",
          day: day || "",
          event_id: eventID || "",
        },
      }),
    }),
    putSchedule: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/schedule/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    addSchedule: builder.mutation({
      query: (body) => ({
        url: `api/schedule`,
        method: "POST",
        body: body,
      }),
    }),
    deleteSchedule: builder.mutation({
      query: (id) => {
        return {
          url: `api/schedule/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddScheduleMutation,
  usePutScheduleMutation,
  useDeleteScheduleMutation,
  useGetSchedulesQuery,
  useLazyGetSchedulesQuery,
} = SchedulesApi;
