import { useEffect, useState } from "react";
import { Input, Modal, Radio, Spin, message } from "antd";
import { comment_icon } from "../../assets/icons/icons";
import { user } from "../../assets/images/images";
import { ThemeButton, PersonalDetailsModal } from "../components";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useAddInvitationMutation } from "../../services/invitation";
import { useSendInviteMutation } from "../../services/slots";
import { EyeOutlined } from "@ant-design/icons"; // Add this import at the top of the file

const optionsWithDisabled = [
  { label: "Priority 1", value: 1 },
  { label: "Priority 2", value: 2 },
  { label: "Priority 3", value: 3 },
];

const DelegateParticipantsCard = ({
  attendee,
  currentUserId,
  currentUserAttendee,
  refetchInvitation,
  currentUserType,
  invites,
  setDetailsModal,
  detailsModal,
  setUserData,
  userData,
  meetings,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sendInvite, { isLoading: addingInvitation }] = useSendInviteMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setNote] = useState("");
  const [priority, setPriority] = useState(0);
  const name = `${attendee?.user?.first_name} ${attendee?.user?.last_name}`;

  const sendInviteFunction = async () => {
    console.log(currentUserAttendee, "currentUserAttendee");
    try {
      const inviteData = {
        eventId: attendee.event,
        receiver: attendee?._id,
        sender: currentUserAttendee?._id,
      };

      const invite = await sendInvite(inviteData);
      console.log(invite, "invite");
      if (invite?.data) {
        message.success(invite?.data?.messsage || "Invited Successfully.");
        setIsModalOpen(false);
        setNote("");
        refetchInvitation();
      } else {
        message.warning(invite?.error?.data?.error || "Something went wrong.");
      }
    } catch (err) {
      console.error("Error sending invite:", err);
      // message.error("An error occurred while sending the invite.");
    }
  };

  console.log(meetings, "meetingsasdasd");
  console.log(attendee, "attendee");
  const filterInvite = meetings?.find(
    (item) => item.receiver?._id === attendee?._id && item.status !== "rejected"
  );

  // useEffect(() => {
  //   if (filterInvite) {
  //     refetchInvitation();
  //   }
  // }, [filterInvite, refetchInvitation]);

  console.log(filterInvite, "filterInvite");

  return (
    <div className="flex justify-center">
      <Modal
        footer={false}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <p className="font-medium text-lg">
          Send invitation to{" "}
          <span className="text-primary font-bold">{name}</span>
        </p>
        <Input.TextArea
          placeholder="Invitation note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={4}
          className="mt-2 mb-2"
        />
        {/* {currentUserType === "sponsor" &&
          attendee?.user?.user_type === "delegate" && (
            <Radio.Group
              onChange={(e) => setPriority(e.target.value)}
              value={priority}
              options={optionsWithDisabled}
              optionType="button"
              buttonStyle="solid"
            />
          )} */}
        <div className="flex justify-end mt-3">
          <ThemeButton
            onClick={sendInviteFunction}
            // disabled={
            //   currentUserType === 'sponsor' &&
            //   attendee?.user?.user_type === 'delegate'
            // }
            content={
              addingInvitation ? (
                <Spin className="pl-7 pr-7" size="small" />
              ) : (
                "Send Invite"
              )
            }
          />
        </div>
      </Modal>
      <div
        className="bg-white rounded-xl p-5 flex flex-col items-center justify-between w-4/5 sm:w-full gap-5"
        style={{ height: 350 }}
      >
        <div className="flex flex-col justify-center items-center">
          <img
            width="80"
            height="80"
            className="w-[80px] h-[80px] rounded-full object-cover"
            src={attendee?.user?.picture || user}
            alt=""
          />
          <NavLink
            onClick={() => {
              setDetailsModal(true);
              setUserData(attendee);
            }}
            className="font-bold underline hover:!text-primary"
          >
            <p className="font-bold text-xl mt-3 text-center">{name}</p>
          </NavLink>
          <p className="text-center font-semibold">
            {attendee?.user?.metadata?.delegate_details?.position || "----"}
          </p>
          <p className="text-center font-semibold">
            {attendee?.user?.metadata?.delegate_details?.company || "----"}
          </p>
          <p className="text-primary text-xs font-medium mt-2">
            {attendee?.user?.metadata?.delegate_details?.phone ||
              attendee?.user?.metadata?.delegate_details?.mobile ||
              "----"}
          </p>
          <p className="text-primary text-xs font-medium">
            {attendee?.user?.email}
          </p>
        </div>
        <div className="flex justify-between w-fill gap-2">
          <ThemeButton
            bgColor={
              filterInvite?.receiver?._id === attendee?._id ? "grey" : ""
            }
            disabled={filterInvite?.receiver?._id === attendee?._id}
            onClick={() => setIsModalOpen(true)}
            content={
              filterInvite?.receiver?._id === attendee?._id
                ? "Invited"
                : "Send Invite"
            }
            className="w-32"
          />
          <ThemeButton
            onClick={() => {
              setDetailsModal(true);
              setUserData(attendee);
            }}
            content={<EyeOutlined style={{ fontSize: "20px" }} />}
          />
          <ThemeButton
            onClick={() => {
              console.log("location.pathname", location);
              localStorage.setItem(
                "chat-previous",
                `${location.pathname}${location.search || ""}`
              );
              navigate(`/chats?user=${attendee?.user?._id}`);
            }}
            content={<img src={comment_icon} alt="" width={20} />}
          />
        </div>
      </div>
    </div>
  );
};

export default DelegateParticipantsCard;
