import { Row, Col } from "antd";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { user } from "../../assets/images/images";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMeetingsQuery } from "../../services/newMeeting";

const NetworkingSessionCard = ({
  networkingDetail,
  onEdit,
  onDelete,
  isAdmin,
  isOtherNetworking,
  filterCurrent,
  currentUserId,
  isUser,
  dontShowMeetings,
}) => {
  console.log(networkingDetail, "networkingDetail");

  console.log(isAdmin, "isUserisUserisUser");
  console.log(filterCurrent, "filterCurrentfilterCurrent");

  return (
    <div className="relative">
      <div className="bg-primary flex items-center justify-center py-2 static">
        <div className="flex text-white flex-col items-center">
          <p className="text-xl md:text-3xl font-bold">
            {networkingDetail?.title}
          </p>
          <p className="md:text-base font-semibold mt-1">
            {networkingDetail?.start_time} - {networkingDetail?.end_time}
          </p>
          <p className="md:text-base font-semibold mt-1">(Exibition Hall)</p>
        </div>
        {isAdmin && (
          <div className="flex absolute right-10">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(networkingDetail)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(networkingDetail))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
      </div>
      <div
        className="bg-white p-4 shadow-md shadow-gray-300 flex flex-col justify-around"
        style={{ minHeight: 234 }}
      >
        <p className="text-primary text-lg md:text-2xl font-bold">
          {isOtherNetworking
            ? "Other Networking Sessions"
            : "Networking Sessions"}
        </p>
        {networkingDetail?.slots?.map((v, i) => {
          // ... existing code ...

          const filterSLotsForPersonalized =
            filterCurrent && v?._id
              ? filterCurrent.filter(
                  (item) =>
                    item?.slot?._id === v._id &&
                    (item?.sender?.user?._id === currentUserId ||
                      item?.receiver?.user?._id === currentUserId)
                )
              : [];

          const filterSLotsForAdmin =
            filterCurrent && v?._id
              ? filterCurrent.filter((item) => item?.slot?._id === v._id)
              : [];

          // ... existing code ...
          // const filterCurrentTime =

          return (
            <Row key={i} className="flex items-center">
              {currentUserId ? (
                <>
                  {filterSLotsForPersonalized?.map(
                    (item, index) =>
                      item?.sender?.user &&
                      item?.receiver?.user && (
                        <div className="w-full" key={index}>
                          {currentUserId && (
                            <Row key={i} className="flex items-center ">
                              <Col xl={5} lg={7} md={5} sm={7} xs={7}>
                                <p className="md:text-lg font-semibold">
                                  {v.title}{" "}
                                </p>
                                <span className="text-[12px] relative left-20">
                                  (Pre Arrange Meeting Area)
                                </span>
                              </Col>
                              <Col xl={13} lg={9} md={13} sm={9} xs={6}>
                                <div
                                  style={{
                                    border: "1px dashed rgba(0,0,0,.8)",
                                    borderBottomWidth: 1,
                                  }}
                                ></div>
                              </Col>
                              <Col xl={6} lg={8} md={6} sm={8} xs={11}>
                                <div className="flex flex-col items-start">
                                  <p className="text-xs font-medium text-end my-4 pl-2">
                                    {item.slot.startTime} | {item.slot.endTime}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          )}
                          <div className="flex justify-between mb-2 pr-2 w-[40%] m-[auto] items-center">
                            <div className="flex items-center">
                              <img
                                src={item?.sender?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2">
                                {item?.sender?.user.first_name +
                                  " " +
                                  item?.sender?.user.last_name}
                              </p>
                            </div>

                            <div className="flex items-center justify-start w-20">
                              <img
                                src={item?.receiver?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2 whitespace-nowrap">
                                {item?.receiver?.user.first_name +
                                  " " +
                                  item?.receiver?.user.last_name}
                              </p>
                            </div>
                            {isOtherNetworking ? (
                              <p className="text-xs font-medium ml-2 whitespace-nowrap bg-primary text-white p-2 rounded-md text-xl">
                                Table {index + 1}
                              </p>
                            ) : (
                              item?.sender?.user.metadata?.atendee_details
                                ?.table_number && (
                                <p className="text-xs font-medium ml-2 whitespace-nowrap bg-primary text-white p-2 rounded-md text-xl">
                                  Table{" "}
                                  {
                                    item?.sender?.user.metadata?.atendee_details
                                      ?.table_number
                                  }
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      )
                  )}
                </>
              ) : (
                <>
                  <>
                    <Col xl={5} lg={7} md={5} sm={7} xs={7}>
                      <p className="md:text-lg font-semibold">{v.title} </p>
                      <span className="text-[12px] relative left-[6.5rem]">
                        (Pre Arrange Meeting Area)
                      </span>
                    </Col>
                    <Col xl={13} lg={9} md={13} sm={9} xs={6}>
                      <div
                        style={{
                          border: "1px dashed rgba(0,0,0,.8)",
                          borderBottomWidth: 1,
                        }}
                      ></div>
                    </Col>
                    <Col xl={6} lg={8} md={6} sm={8} xs={11}>
                      <div className="flex flex-col items-start">
                        <p className="text-xs font-medium text-end my-4 pl-2">
                          {v.startTime} | {v.endTime}
                        </p>
                      </div>
                    </Col>
                  </>
                  {filterSLotsForAdmin?.map(
                    (item, index) =>
                      !dontShowMeetings &&
                      item?.sender?.user &&
                      item?.receiver?.user && (
                        <div className="w-full">
                          <div className="flex justify-between mb-2 pr-2 w-[40%] m-[auto]">
                            <div className="flex items-center">
                              <img
                                src={item?.sender?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2">
                                {item?.sender?.user.first_name +
                                  " " +
                                  item?.sender?.user.last_name}
                              </p>
                            </div>

                            <div className="flex items-center justify-start w-20">
                              <img
                                src={item?.receiver?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2 whitespace-nowrap">
                                {item?.receiver?.user.first_name +
                                  " " +
                                  item?.receiver?.user.last_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default NetworkingSessionCard;
