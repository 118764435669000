import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const MessageApi = createApi({
  reducerPath: "message",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMessage: builder.query({
      query: ({ result_per_page = 10000, sender = "", receiver = "" }) => ({
        url: "api/message",
        method: "GET",
        params: {
          result_per_page,
          sender,
          receiver,
        },
      }),
    }),
    addMessage: builder.mutation({
      query: (body) => ({
        url: `api/message`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetMessageQuery, useAddMessageMutation } = MessageApi;
