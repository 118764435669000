import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const TicketsApi = createApi({
  reducerPath: "tickets",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000, status = "" }) => ({
        url: `api/ticket`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
          status: status,
        },
      }),
    }),
    putTicket: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/ticket/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    addTicket: builder.mutation({
      query: (body) => ({
        url: `api/ticket`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useAddTicketMutation,
  usePutTicketMutation,
  useGetTicketsQuery,
  useLazyGetTicketsQuery,
} = TicketsApi;
