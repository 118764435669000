import { Input } from "antd";
import { useEffect, useRef } from "react";

const ThemeInput = ({
  label,
  placeholder,
  value,
  labelPrimary,
  textMd,
  className,
  password,
  onChange,
  name,
  disabled,
  onKeyDown,
  type,
  register, // Added for react-hook-form integration
  error, // Added for error display
  setValue, // Optional: If you want to set value programmatically
  ...rest // To spread other props
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (register) {
      register(name, { shouldUnregister: true }); // Register the input field
    }
  }, [register, name]);

  return (
    <div className={className}>
      <p
        className={`${labelPrimary ? "text-primary" : ""} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1`}
      >
        {label}
      </p>
      {password ? (
        <Input.Password
          ref={inputRef}
          onKeyDown={onKeyDown}
          name={name}
          placeholder={placeholder}
          value={value}
          className={`h-10 font-medium ${error ? "border-red-500" : ""}`}
          onChange={(e) => {
            onChange && onChange(e); // Maintain onChange functionality
            if (setValue) {
              setValue(name, e.target.value); // Optional: Set value programmatically
            }
          }}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <Input
          type={type || "text"}
          ref={inputRef}
          onKeyDown={onKeyDown}
          name={name}
          placeholder={placeholder}
          value={value}
          className={`h-10 font-medium ${error ? "border-red-500" : ""}`}
          onChange={(e) => {
            onChange && onChange(e); // Maintain onChange functionality
            if (setValue) {
              setValue(name, e.target.value); // Optional: Set value programmatically
            }
          }}
          disabled={disabled}
          {...rest}
        />
      )}
      {/* Display error message */}
    </div>
  );
};

export default ThemeInput;
