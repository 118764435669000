import {
  Col,
  Select,
  TimePicker,
  DatePicker,
  Input,
  Modal,
  Row,
  Spin,
} from "antd";

import { Input as ChakraInput } from "@chakra-ui/react";
import {
  calender_colored_icon,
  add_icon,
  add_colored_icon,
  delete_icon,
  upload_icon,
} from "../../assets/icons/icons";
import {
  ThemeButton,
  ThemeInput,
  ThemeSelect,
  ImageUpload,
} from "../components";
import dayjs from "dayjs";
import { uploadImage } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import "./index.css";
import Dragger from "antd/es/upload/Dragger";
import { useDeleteSlotMutation } from "../../services/slots";

const AgendaModal = ({
  isModalOpen,
  onClose,
  onSubmit,
  formData,
  setFormData,
  meetings,
  setMeetings,
  deletedMeetings,
  setDeletedMeetings,
  isLoading,
  eventID,
  speakers,
  headings,
  days,
  halls,
  activityToBeDoneList,
  mode,
  event,
}) => {
  const [image, setImage] = useState(formData?.image || "");
  const [uploading, setUploading] = useState(false);
  const [uploadingPDF, setUploadingPDF] = useState(false);

  useEffect(() => {
    setImage(formData.image);
  }, [formData]);

  console.log(formData, "formData");

  const onChangeStart = (e) => {
    console.log(e.target.value, "time");
    setFormData({
      ...formData,
      start_time: e.target.value,
    });
  };

  const onChangeStartNew = (event) => {
    const timeValue = event.target.value;
    const formattedTime = dayjs(timeValue, "HH:mm").format("hh:mm A"); // Convert to 12-hour format
    setFormData((prevData) => ({
      ...prevData,
      start_time: formattedTime,
    }));
  };

  const onChangeEndNew = (event) => {
    const timeValue = event.target.value;
    const formattedTime = dayjs(timeValue, "HH:mm").format("hh:mm A"); // Convert to 12-hour format
    setFormData((prevData) => ({
      ...prevData,
      end_time: formattedTime,
    }));
  };

  const onChangeEnd = (_, time) => {
    setFormData({
      ...formData,
      end_time: time,
    });
  };

  const onChangeSlotDate = (date, index) => {
    let slotsData = [...meetings];
    slotsData[index] = {
      ...slotsData[index],
      date: date,
    };
    setMeetings(slotsData);
  };

  const onChangeSlotStart = (event, index) => {
    const timeValue = event.target.value; // Get time in 24-hour format (HH:mm)
    const formattedTime = dayjs(timeValue, "HH:mm").format("hh:mm A"); // Convert to 12-hour format
    let slotsData = [...meetings];
    slotsData[index] = {
      ...slotsData[index],
      startTime: formattedTime,
    };
    setMeetings(slotsData);
  };

  const onChangeSlotEnd = (event, index) => {
    const timeValue = event.target.value; // Get time in 24-hour format (HH:mm)
    const formattedTime = dayjs(timeValue, "HH:mm").format("hh:mm A"); // Convert to 12-hour format
    let slotsData = [...meetings];
    slotsData[index] = {
      ...slotsData[index],
      endTime: formattedTime,
    };
    setMeetings(slotsData);
  };

  const onChangeMeetingType = (value, index) => {
    let slotsData = [...meetings];
    slotsData[index] = {
      ...slotsData[index],
      meetingType: value,
    };
    setMeetings(slotsData);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const onChangeDescription = (e, i) => {
    let points = [...formData.description];
    points[i] = e.target.value;
    setFormData({
      ...formData,
      description: points,
    });
  };

  const handleChangeType = (e) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      type: value,
      speaker: null,
    });
    setMeetings([]);
  };

  const addSlot = () => {
    let slotsData = [...meetings];
    slotsData.push({
      title: `Meeting ${slotsData.length + 1}`,
      startTime: null,
      endTime: null,
      event: eventID,
    });
    setMeetings(slotsData);
  };

  const removeSlot = (id) => {
    const filterMeeting = meetings?.filter((item, index) => index !== id);
    setMeetings(filterMeeting);

    if (mode === "edit") {
      const mee = meetings[id];

      let meetingsData = [...deletedMeetings];
      meetingsData.push(mee);

      setDeletedMeetings(meetingsData);
      console.log(mee, "meemee");

      // setDeletedMeetings((prev) => ({ ...prev, meetings: mee }));
    }
  };

  const addPoint = () => {
    let points = [...formData.description];
    points.push("");
    setFormData({
      ...formData,
      description: points,
    });
  };

  const removePoint = (index) => {
    let points = [...formData.description];
    points.splice(index, 1);
    setFormData({ ...formData, description: points });
  };

  const handleImage = async (e) => {
    const res = await uploadImage(e.target.files[0]);
    setFormData({ ...formData, image: res });
    setImage(res);
  };

  const handleFile = async (info, type) => {
    setUploadingPDF(true);

    const { status } = info.file;
    if (status !== "uploading") {
      const res = await uploadImage(info.file.originFileObj);

      setFormData({
        ...formData,
        abstract: res,
      });
      setUploadingPDF(false);
    }
  };
  console.log("meetings", meetings);
  console.log("meetingsmeetingsmeetings", meetings);

  return (
    <Modal
      width={800}
      title={`${mode === "create" ? "Add" : "Update"} Agenda`}
      footer={false}
      open={isModalOpen}
      onCancel={onClose}
    >
      <Row gutter={{ xs: 8, sm: 20, md: 20, lg: 20 }}>
        <Col span={24} className="mt-4">
          <div>
            <p className="font-medium mb-1 text-primary">Activity to be done</p>
            <ThemeSelect
              placeholder={"select activity to be done"}
              value={formData.type}
              onChange={(value) =>
                handleChangeType({
                  target: { value, name: "type" },
                })
              }
              options={activityToBeDoneList}
              className={"w-full"}
            />
          </div>
        </Col>
        {formData.type !== "networking" &&
          formData.type !== "other_networking" && (
            <Col
              span={
                formData.type !== "sponsor-presentation" &&
                formData.type !== "registration" &&
                formData.type !== "coffe-break" &&
                formData.type !== "breakfast" &&
                formData.type !== "lunch-break" &&
                formData.type !== "cocktail-break"
                  ? 12
                  : 24
              }
              className="mt-4"
            >
              <ThemeInput
                textMd
                label={"Title"}
                placeholder={"title"}
                labelPrimary
                name={"title"}
                value={formData.title}
                onChange={handleChange}
              />
            </Col>
          )}
        {formData.type !== "networking" &&
          formData.type !== "other_networking" &&
          formData.type !== "sponsor-presentation" &&
          formData.type !== "registration" &&
          formData.type !== "breakfast" &&
          formData.type !== "coffe-break" &&
          formData.type !== "lunch-break" &&
          formData.type !== "cocktail-break" && (
            <Col span={12} className="mt-4">
              <ThemeInput
                textMd
                label={"Short title"}
                placeholder={"short title"}
                labelPrimary
                name={"short_title"}
                value={formData.short_title}
                onChange={handleChange}
              />
            </Col>
          )}
        <Col span={12} className="mt-4">
          <p className="font-medium mb-1 text-primary">Start Time</p>

          <ChakraInput
            placeholder="Select Time"
            size="md"
            type="time"
            value={
              formData.start_time
                ? dayjs(formData.start_time, "hh:mm A").format("HH:mm")
                : ""
            }
            onChange={onChangeStartNew}
            className="w-full h-10 font-medium"
          />
        </Col>
        <Col span={12} className="mt-4">
          <p className="font-medium mb-1 text-primary">End Time</p>

          <ChakraInput
            placeholder="Select Time"
            size="md"
            type="time"
            value={
              formData.end_time
                ? dayjs(formData.end_time, "hh:mm A").format("HH:mm")
                : ""
            }
            onChange={onChangeEndNew}
            className="w-full h-10 font-medium"
          />
        </Col>
        {event?.event_hall !== "single-hall" && (
          <div>
            {formData.type !== "networking" &&
              formData.type !== "other_networking" &&
              formData.type !== "sponsor-presentation" &&
              formData.type !== "registration" &&
              formData.type !== "coffe-break" &&
              formData.type !== "breakfast" &&
              formData.type !== "panel-discussion" &&
              formData.type !== "lunch-break" &&
              formData.type !== "cocktail-break" && (
                <Col span={12} className="mt-4">
                  <p className="font-medium mb-1 text-primary">Hall</p>
                  <ThemeSelect
                    placeholder={"select hall"}
                    value={formData.hall}
                    onChange={(value) =>
                      handleChange({
                        target: { value, name: "hall" },
                      })
                    }
                    options={halls}
                    className={"w-full"}
                  />
                </Col>
              )}
          </div>
        )}
        <Col
          span={
            formData.type !== "networking" &&
            formData.type !== "other_networking" &&
            formData.type !== "sponsor-presentation" &&
            formData.type !== "registration" &&
            formData.type !== "breakfast" &&
            formData.type !== "coffe-break" &&
            formData.type !== "lunch-break" &&
            formData.type !== "cocktail-break"
              ? 12
              : 24
          }
          className="mt-4"
        >
          <p className="font-medium mb-1 text-primary">Day</p>
          <ThemeSelect
            placeholder={"select day"}
            value={formData.day}
            onChange={(value) =>
              handleChange({
                target: { value, name: "day" },
              })
            }
            options={days}
            className={"w-full"}
          />
        </Col>
        {/* <Col className="my-5 w-[200px]">
          <Dragger
            name="file"
            multiple={false}
            showUploadList={false}
            onChange={(info) => handleFile(info, "thumb")}
          >
            {uploading ? (
              <Spin />
            ) : (
              <>
                <div className="flex justify-center mb-1">
                  <img src={upload_icon} alt="" width={40} />
                </div>
                <p className="">Abstract</p>
              </>
            )}

            <div className="flex justify-center mt-1">
              <ThemeButton disabled={uploading} content={"Browse File"} />
            </div>
          </Dragger>
        </Col> */}
        {formData.type !== "networking" &&
          formData.type !== "other_networking" &&
          formData.type !== "panel-discussion" &&
          formData.type !== "registration" &&
          formData.type !== "breakfast" &&
          formData.type !== "coffe-break" &&
          formData.type !== "lunch-break" &&
          formData.type !== "cocktail-break" && (
            <Col span={24} className="mt-4">
              <p className="font-medium text-primary">Description</p>
              {formData.description.length > 0 &&
                formData.description?.map((item, index) => {
                  return (
                    <div className="flex items-center" key={index}>
                      <div className="bg-primary mt-2 rounded-full h-2 w-2"></div>
                      <ThemeInput
                        placeholder={"enter here your point"}
                        className="w-full h-10 font-medium point-input mx-2"
                        value={item}
                        onChange={(e) => onChangeDescription(e, index)}
                      />
                      <img
                        src={delete_icon}
                        alt=""
                        width={20}
                        className="cursor-pointer mt-5"
                        onClick={() => removePoint(index)}
                      />
                    </div>
                  );
                })}
              <div
                className="flex items-center justify-center mt-4 border-2 border-dashed border-primary p-2 cursor-pointer hover:bg-gray-50"
                onClick={addPoint}
              >
                <img src={add_colored_icon} alt="" width={20} />
                <p className="ml-2 font-medium">Add Point</p>
              </div>
            </Col>
          )}

        {(formData.type === "keynote" ||
          formData.type === "networking" ||
          formData.type === "other_networking" ||
          formData.type === "sponsor-presentation") && (
          <Col span={24} className="mt-4">
            <p className="font-medium mb-1 text-primary">
              {formData.type === "keynote" ||
              formData.type === "sponsor-presentation"
                ? "Speaker"
                : formData.type === "networking" && "Meeting slots"}
            </p>
            {formData.type === "keynote" ||
            formData.type === "sponsor-presentation" ? (
              <ThemeSelect
                showSearch
                optionFilterProp="label"
                placeholder={"select speaker"}
                value={formData.speaker}
                onChange={(value) =>
                  handleChange({
                    target: { value, name: "speaker" },
                  })
                }
                options={speakers}
                className={"w-full"}
              />
            ) : formData?.type === "networking" ? (
              <>
                <div id="slots-container">
                  {meetings?.map((slot, index) => {
                    console.log(meetings, "meetings");
                    return (
                      <Row
                        gutter={{ xs: 8, sm: 20, md: 20, lg: 20 }}
                        className="mt-3"
                        key={index}
                        onClick={() =>
                          onChangeMeetingType("sponsor-to-all", index)
                        }
                      >
                        <Col span={10}>
                          <ChakraInput
                            placeholder="Select Time"
                            size="md"
                            type="time"
                            value={
                              slot?.startTime &&
                              dayjs(slot.startTime, "hh:mm A").format("HH:mm") // Convert 12-hour format back to 24-hour for display
                            }
                            onChange={(event) =>
                              onChangeSlotStart(event, index)
                            }
                            className="w-full h-10 font-medium"
                          />
                        </Col>
                        <Col span={10}>
                          <ChakraInput
                            placeholder="Select Time"
                            size="md"
                            type="time"
                            value={
                              slot?.endTime &&
                              dayjs(slot.endTime, "hh:mm A").format("HH:mm") // Convert 12-hour format back to 24-hour for display
                            }
                            onChange={(event) => onChangeSlotEnd(event, index)}
                            className="w-full h-10 font-medium"
                          />
                        </Col>
                        <Col
                          style={{ justifyContent: "right" }}
                          span={2}
                          className="flex items-center"
                        >
                          <img
                            src={delete_icon}
                            alt=""
                            width={20}
                            className="cursor-pointer"
                            onClick={() => removeSlot(index)}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div
                  className="flex items-center justify-center mt-5 border-2 border-dashed border-primary p-2 cursor-pointer hover:bg-gray-50"
                  onClick={addSlot}
                >
                  <img src={add_colored_icon} alt="" width={20} />
                  <p className="ml-2 font-medium">Add Slot</p>
                </div>
              </>
            ) : (
              formData?.type === "other_networking" && (
                <>
                  <div id="slots-container">
                    {meetings?.map((slot, index) => {
                      console.log(meetings, "meetings");
                      return (
                        <Row
                          gutter={{ xs: 8, sm: 20, md: 20, lg: 20 }}
                          className="mt-3"
                          key={index}
                          onClick={() =>
                            onChangeMeetingType("delegate-to-all", index)
                          }
                        >
                          <Col span={10}>
                            <ChakraInput
                              placeholder="Select Time"
                              size="md"
                              type="time"
                              value={
                                slot?.startTime &&
                                dayjs(slot.startTime, "hh:mm A").format("HH:mm") // Convert 10-hour format back to 24-hour for display
                              }
                              onChange={(event) =>
                                onChangeSlotStart(event, index)
                              }
                              className="w-full h-10 font-medium"
                            />
                          </Col>
                          <Col span={10}>
                            <ChakraInput
                              placeholder="Select Time"
                              size="md"
                              type="time"
                              value={
                                slot?.endTime &&
                                dayjs(slot.endTime, "hh:mm A").format("HH:mm") // Convert 12-hour format back to 24-hour for display
                              }
                              onChange={(event) =>
                                onChangeSlotEnd(event, index)
                              }
                              className="w-full h-10 font-medium"
                            />
                          </Col>
                          <Col
                            style={{ justifyContent: "right" }}
                            span={2}
                            className="flex items-center"
                          >
                            <img
                              src={delete_icon}
                              alt=""
                              width={20}
                              className="cursor-pointer"
                              onClick={() => removeSlot(index)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  <div
                    className="flex items-center justify-center mt-5 border-2 border-dashed border-primary p-2 cursor-pointer hover:bg-gray-50"
                    onClick={addSlot}
                  >
                    <img src={add_colored_icon} alt="" width={20} />
                    <p className="ml-2 font-medium">Add Slot</p>
                  </div>
                </>
              )
            )}
          </Col>
        )}
        {formData.type === "sponsor-presentation" && (
          <Col span={24} className="mt-4">
            <ImageUpload image={image} onChange={(e) => handleImage(e)} />
          </Col>
        )}
        {formData.type === "panel-discussion" && (
          <Col span={24} className="mt-4">
            <p className="font-medium mb-1 text-primary">Moderator</p>
            <ThemeSelect
              showSearch
              optionFilterProp="label"
              placeholder={"select moderator"}
              value={formData.moderator}
              onChange={(value) =>
                handleChange({
                  target: { value, name: "moderator" },
                })
              }
              options={speakers}
              className={"w-full"}
            />
          </Col>
        )}
        {formData.type === "panel-discussion" && (
          <Col span={24} className="mt-4">
            <p className="font-medium mb-1 text-primary">Panellist</p>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="label"
              placeholder={"select panellist"}
              value={formData.panellist}
              onChange={(value) =>
                handleChange({
                  target: { value, name: "panellist" },
                })
              }
              options={speakers}
              className={"w-full"}
            />
          </Col>
        )}
      </Row>

      <div className="flex justify-end mt-6">
        <ThemeButton
          onClick={onClose}
          content={"Close"}
          className={"w-32 !text-primary hover:!bg-themeGray-light"}
          bgColor={"bg-themeGray-light"}
        />
        <ThemeButton
          disabled={!formData.type}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {mode === "create" && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{mode === "create" ? "Save" : "Update"}</p>
                </div>
              )}
            </>
          }
          className={"ml-3 w-32"}
          onClick={onSubmit}
        />
      </div>
    </Modal>
  );
};

export default AgendaModal;
