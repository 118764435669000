import { useEffect, useState } from "react";
import { Spin, Form, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { login_icon } from "../../assets/icons/icons";
import { big_banner, logo, logo_white } from "../../assets/images/images";
import { ThemeButton, ThemeInput } from "../../components/components";
import { useSetLoginMutation } from "../../services/auth";
import { addKeyToStorage } from "../../helpers/helpers";

const LoginMob = ({
  onClick,
  email,
  setEmail,
  password,
  setPassword,
  isLoading,
}) => {
  return (
    <div className="md:hidden flex flex-col justify-center items-center bg-white min-h-screen px-10">
      <img src={logo} alt="" className="-mt-6" />
      <p className="text-center font-semibold text-sm -mt-4">
        You can login with the credentials sent to your email and see the Event
        Agenda and invites from the Sponsors.
      </p>
      <Form name="normal_login" className="login-form w-full mt-5">
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: `Please input your Email!`,
            },
          ]}
        >
          <ThemeInput
            label={"Email"}
            placeholder={"Email"}
            labelPrimary
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <ThemeInput
            label={"Password"}
            placeholder={"Password"}
            labelPrimary
            password
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Form.Item>
        <Form.Item className="flex justify-end">
          <NavLink className="hover:!text-primary">Forget Password?</NavLink>
        </Form.Item>

        <Form.Item className="flex justify-center">
          <ThemeButton
            type="primary"
            htmlType="submit"
            onClick={onClick}
            className={"!h-10 w-32 flex"}
            content={
              <>
                {isLoading ? (
                  <Spin className="mt-1" />
                ) : (
                  <div className="flex items-center">
                    <img src={login_icon} alt="" width={21} height={21} />
                    <p className="ml-2 text-lg">Log in</p>
                  </div>
                )}
              </>
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
};

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [setLogin, { isLoading }] = useSetLoginMutation();

  const handleLogin = async () => {
    if (email && password) {
      const response = await setLogin({
        email: email,
        password: password,
      });
      if (response?.data?.status === 200) {
        const userType = response.data.user.user_type;

        addKeyToStorage(`${userType}Token`, response.data.token);
        if (response.data.user.user_type === "admin") {
          navigate("/dashboard");
          setAuthUser(response.data.user._id, "admin");
        } else if (response.data.user.user_type === "delegate") {
          navigate("/upcoming-events");
          setAuthUser(response.data.user._id, "delegate");
        } else if (response.data.user.user_type === "sponsor") {
          navigate("/upcoming-events");
          setAuthUser(response.data.user._id, "sponsor");
        }
      } else {
        message.error(response?.error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    const getUseronlocal = localStorage.getItem("userType");
    const getUserSession = sessionStorage.getItem("userType");

    if (getUserSession === "delegate" || getUserSession === "sponser") {
      navigate("/upcoming-events");
    } else if (getUseronlocal === "admin") {
      navigate("/dashboard");
    } else if (getUseronlocal === "delegate" || getUseronlocal === "sponser") {
      navigate("/upcoming-events");
    }
  }, []);
  return (
    <>
      <div className="h-screen md:flex hidden">
        <div className="bg-primary w-2/4 h-full flex justify-end items-center">
          <div
            className="rounded-bl-xl rounded-tl-xl shadow-[-10px_5px_30px_-10px_rgba(0,0,0,.2)] flex justify-center items-center"
            style={{ height: "87vh", width: "85%" }}
          >
            <img src={logo_white} width={300} alt="" />
          </div>
        </div>
        <div className="bg-white w-2/4 h-full flex justify-start items-center">
          <div
            className="rounded-br-xl rounded-tr-xl shadow-[30px_5px_30px_-15px_rgba(0,0,0,.2)] flex flex-col justify-center items-center px-10"
            style={{ height: "87vh", width: "85%" }}
          >
            <p className="text-center font-semibold mt-5">
              You can login with the credentials sent to your email and see the
              Event Agenda and invites from the Sponsors.
            </p>
            <Form name="normal_login" className="login-form w-full mt-5">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: `Please input your Email!`,
                  },
                ]}
              >
                <ThemeInput
                  label={"Email"}
                  placeholder={"Email"}
                  labelPrimary
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value.toLowerCase().trim())
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <ThemeInput
                  label={"Password"}
                  placeholder={"Password"}
                  labelPrimary
                  password
                  value={password}
                  onChange={(e) => setPassword(e.target.value.trim())}
                />
              </Form.Item>

              <Form.Item className="flex justify-center">
                <ThemeButton
                  type="primary"
                  htmlType="submit"
                  onClick={handleLogin}
                  className={"!h-10 w-32 flex"}
                  content={
                    <>
                      {isLoading ? (
                        <Spin className="mt-1" />
                      ) : (
                        <div className="flex items-center">
                          <img src={login_icon} alt="" width={21} height={21} />
                          <p className="ml-2 text-lg">Log in</p>
                        </div>
                      )}
                    </>
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <LoginMob
        onClick={handleLogin}
        email={email}
        setEmail={(e) => setEmail(e)}
        password={password}
        setPassword={(e) => setPassword(e)}
        isLoading={isLoading}
      />
    </>
  );
};

export default Login;
