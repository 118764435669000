import { useState, useMemo } from "react";
import {
  DelegateParticipantsCard,
  PersonalDetailsModal,
  ThemeButton,
  ThemeInput,
} from "../../../../components/components";
import { Row, Col, Pagination, Tabs, Input } from "antd";
import { CSVLink } from "react-csv";

const Attendees = ({
  attendees,
  currentUser,
  refetchInvitation,
  invitations,
  meetings,
}) => {
  const currentUserAttendee =
    attendees && attendees.length
      ? attendees.filter((v) => v.user?._id == currentUser?.id)
      : [];
  const invites = invitations?.invitations?.data;
  const [detailsModal, setDetailsModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [attendee, setAttendee] = useState("sponsor");
  const [userData, setUserData] = useState({});

  const setPagination = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setCurrentPage(page);
  };

  const handleTabChange = (key) => {
    setSearchQuery(""); // Reset search query when switching tabs
    setAttendee(key); // Update attendee type when changing tabs
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const mappedData = useMemo(() => {
    if (!attendees) return []; // Handle case where attendees is undefined
    return attendees?.filter(
      (item) =>
        item?.user?.user_type === attendee &&
        (item.user.first_name
          ?.toLowerCase()
          .startsWith(searchQuery.toLowerCase()) ||
          item.user.last_name
            ?.toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          item?.user?.metadata?.delegate_details?.company
            ?.toLowerCase()
            .startsWith(searchQuery.toLowerCase()))
    );
  }, [attendees, attendee, searchQuery]);

  console.log(mappedData, "mappedData");
  const filteredDelegateAttendees = useMemo(() => {
    if (!mappedData) return []; // Handle case where mappedData is undefined
    return mappedData
      ?.filter((item) => item.user?._id !== currentUser.id)
      .map((item, index) => ({
        No: index + 1,
        AttendeeName: item.user.first_name + " " + item.user.last_name,
        CompanyName: item?.user?.metadata?.delegate_details?.company,
        Contact: item?.user?.metadata?.delegate_details?.phone,
        Email: item.user.email,
        JobTitle: item?.user?.metadata?.delegate_details?.position,
      }));
  }, [mappedData, currentUser.id]);


  const CardComponent = () => {
    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {mappedData &&
            mappedData?.slice(minValue, maxValue).map((v, i) =>
              currentUser.id !== v?.user?._id ? (
                <Col
                  className="mb-4"
                  key={i}
                  xl={6}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                >
                  <DelegateParticipantsCard
                    attendee={v}
                    invites={invites}
                    currentUserType={currentUser.type}
                    currentUserId={currentUser.id}
                    currentUserAttendee={currentUserAttendee[0]}
                    refetchInvitation={refetchInvitation}
                    setDetailsModal={setDetailsModal}
                    detailsModal={detailsModal}
                    setUserData={setUserData}
                    userData={userData}
                    meetings={meetings}
                  />
                </Col>
              ) : null
            )}
        </Row>

        <div className="flex justify-center">
          <Pagination
            defaultCurrent={1}
            total={mappedData?.length}
            current={currentPage}
            defaultPageSize={20}
            pageSizeOptions={[20, 40, 60, 80, 100]}
            onChange={(page, pageSize) => setPagination(page, pageSize)}
          />
        </div>
      </>
    );
  };

  return (
    <div className="w-full">
      <Tabs defaultActiveKey="sponsor" onChange={handleTabChange}>
        <Tabs.TabPane tab="Event Sponsors " key="sponsor">
          <div className="flex  items-center gap-3">
            <ThemeInput
              value={searchQuery}
              onChange={handleSearchChange}
              className=" w-[300px]"
              placeholder={`Search ${attendee}s`}
            />
            {filteredDelegateAttendees?.length > 0 && (
              <ThemeButton
                content={
                  <p className="w-full flex items-center justify-end">
                    <CSVLink
                      data={filteredDelegateAttendees}
                      filename={`${attendee}-user-data.csv`}
                      className="hover:text-white"
                      target="_blank"
                    >
                      Download CSV
                    </CSVLink>
                  </p>
                }
              />
            )}
          </div>
          <CardComponent />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pharma/Biotech Sponsors" key="delegate">
          <div className="flex  items-center gap-3">
            <ThemeInput
              value={searchQuery}
              onChange={handleSearchChange}
              className=" w-[300px]"
              placeholder={`Search ${attendee}s`}
            />
            {filteredDelegateAttendees?.length > 0 && (
              <ThemeButton
                content={
                  <p className="w-full flex items-center justify-end">
                    <CSVLink
                      data={filteredDelegateAttendees}
                      filename={`${attendee}-user-data.csv`}
                      className="hover:text-white"
                      target="_blank"
                    >
                      Download CSV
                    </CSVLink>
                  </p>
                }
              />
            )}
          </div>
          <CardComponent />
        </Tabs.TabPane>
      </Tabs>

      <PersonalDetailsModal
        userData={userData}
        isModalOpen={detailsModal}
        onClose={() => setDetailsModal(false)}
      />
    </div>
  );
};

export default Attendees;
