import { useEffect, useState } from "react";
import { left_arrow_icon } from "../../../assets/icons/icons";
import {
  SecondContainer,
  ThemeTable,
  PersonalDetailsModal,
  ThemeButton,
  SupportTicket,
} from "../../../components/components";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import {
  Tabs,
  Row,
  Col,
  message,
  Alert,
  Badge,
  Tooltip,
  Modal,
  Select,
  Button,
  Spin,
} from "antd";
import EventInfo from "./eventInfo/eventInfo";
import EventAgenda from "../../eventDetails/eventAgenda/eventAgenda";
import { useGetEventByIDQuery } from "../../../services/events";
import {
  useGetInvitationQuery,
  usePutInvitationMutation,
  useDeleteInvitationMutation,
} from "../../../services/invitation";
import { getUserInfo } from "../../../helpers/helpers";
import PersonalAgenda from "./personalAgenda/personalAgenda";
import Attendees from "./attendees/attendees";
import {
  MessageOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  UndoOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useLazyGetAttendeesQuery } from "../../../services/attendees";
import dayjs from "dayjs";
import { useGetMeetingsByUserIdQuery } from "../../../services/newMeeting";
import {
  useGetSlotsByTypeAndNoMeetingsQuery,
  useUpdateSlotMutation,
  useAcceptInviteMutation,
  useGetAttendeeByIdQuery,
} from "../../../services/slots";
import SpinnerModal from "../../../components/SpinnerModal";
import { Spinner } from "@chakra-ui/react";
import EventBooklet from "../../eventDetails/eventpdf/eventpdf";

const Details = () => {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedKey, setSelectedKey] = useState(
    searchParams.get("tab") || "1"
  );

  const [selectedStatusKey, setSelectedStatusKey] = useState("1");
  const [detailsModal, setDetailsModal] = useState(false);
  const { data: event, isLoading: eventLoading } = useGetEventByIDQuery(id);
  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation,
  } = useGetInvitationQuery({
    eventId: id,
    sender: getUserInfo().id,
    receiver: getUserInfo().id,
  });

  const [
    acceptInvite,
    { isLoading: inviteAcceptLoading, error: inviteAcceptError },
  ] = useAcceptInviteMutation();

  // const [
  //   putInvitation,
  //   { isLoading: updatingInvitation, error: updateInvitationError },
  // ] = usePutInvitationMutation();

  const [upcomingEvent, setUpcomingEvent] = useState({});
  const [pendingData, setPendingData] = useState([]);
  const [acceptedData, setAcceptedData] = useState([]);
  const [declinedData, setDeclinedData] = useState([]);
  const [meetingNotifications, setMeetingNotifications] = useState([]);
  const [userData, setUserData] = useState({});
  const [currentAttendees, setCurrentAttendees] = useState({});

  const [getAttendees, { isLoading: fetchAtendee }] =
    useLazyGetAttendeesQuery();



  const [meetingType, setMeetingType] = useState("sponsor-to-all"); // Default type, or set as needed

  const [currentAttendee, setCurrentAttendee] = useState({});

  const [
    deletInvitation,
    {
      isLoading: deleteInvitationLoading,
      error: deleteInvitationLoadingError,
      // refetch: refetchInvitatio,
    },
  ] = useDeleteInvitationMutation();

  const {
    data: atendeeData,
    isLoading: atendeeloading,
    isSuccess: isAtendeeSuccess,
  } = useGetAttendeeByIdQuery({ id: getUserInfo().id, eventId: id });

  // Local state to control when to trigger the second query

  // Conditionally fetch meetings data based on the attendee ID

  console.log(atendeeData, "atendeeData");
  const {
    data: meetingsData,
    isLoading: meetingloading,
    refetch: refetchMeeting,
  } = useGetMeetingsByUserIdQuery(
    isAtendeeSuccess && { userId: atendeeData[0]?._id, eventId: id }
  );

  console.log(meetingsData, "meetingsData");

  const sortMeetings = (meetings) => {
    return meetings.sort((a, b) => {
      // Sort by day first
      const dayA = a.slot?.day === "Day 1" ? 1 : 2; // Assign priority to Day 1
      const dayB = b.slot?.day === "Day 1" ? 1 : 2;

      if (dayA !== dayB) return dayA - dayB; // Sort by day

      // Sort by slot presence first
      if (a.slot && !b.slot) return -1;
      if (!a.slot && b.slot) return 1;

      // Convert start times to 24-hour format for comparison
      const timeA = dayjs(a.slot?.startTime, "hh:mm A");
      const timeB = dayjs(b.slot?.startTime, "hh:mm A");

      // Sort by time in ascending order
      return timeA.diff(timeB);
    });
  };

  const sortAll = sortMeetings(meetingsData?.meetings.filter((item) => item?.status === "pending" || item?.status === "accepted" || item?.status === "rejected") || []);

  console.log(sortAll, "sortAll")




  const filterPending = sortMeetings(
    meetingsData?.meetings?.filter(
      (item) =>
        item?.status === "pending" &&
        (item?.receiver?._id === atendeeData[0]?._id ||
          item?.sender?._id === atendeeData[0]?._id)
    ) || []
  );

  const filterAccepted = sortMeetings(
    meetingsData?.meetings?.filter((item) => item?.status === "accepted") || []
  );

  const filterRejected = sortMeetings(
    meetingsData?.meetings?.filter((item) => item?.status === "rejected") || []
  );

  console.log(meetingsData, "meetingsData");

  const getUser = async () => {
    const response = await getAttendees({
      eventID: id,
      userID: getUserInfo().id,
      currentPage: 1,
      resultPerPage: 1,
    });
    setCurrentAttendee(response?.data?.attendees?.data[0]);
  };

  console.log(currentAttendee, "currentAttendee")
  function sortObjectsByTime(objects) {
    // Define a custom sorting function
    function compareTime(a, b) {
      const timeFormat = "hh:mm A"; // Specify the time format
      const timeA = dayjs(a.meeting?.start_time, timeFormat);
      const timeB = dayjs(b.meeting?.start_time, timeFormat);

      if (timeA.isBefore(timeB)) {
        return -1;
      } else if (timeA.isAfter(timeB)) {
        return 1;
      } else {
        return 0;
      }
    }

    // Sort the objects using the custom sorting function
    const sortedObjects = objects.slice().sort(compareTime);

    return sortedObjects;
  }

  console.log("invitaions data length", invitations?.invitations?.data);

  console.log(event, "eventsdddddddddddd")
  useEffect(() => {
    getUser();
    setUpcomingEvent(event?.events?.data[0]);
    const invitationList = invitations?.invitations?.data || [];
    console.log("invitationList---->", invitationList);
    const pending = [];
    const accepted = [];
    const declined = [];
    const meetingNotification = [];
    for (var i = 0; i < invitationList.length; i++) {
      const status = invitationList[i].status;
      if (status == "invited") {
        if (
          invitationList[i].sender &&
          invitationList[i].sender?._id !== getUserInfo().id
        ) {
          meetingNotification.push(invitationList[i]);
        }
        pending.push(invitationList[i]);
      } else if (status == "accepted") {
        accepted.push(invitationList[i]);
      } else {
        declined.push(invitationList[i]);
      }
    }
    setMeetingNotifications(meetingNotification);
    setPendingData(pending);

    const acceptedSorted = [
      ...sortObjectsByTime(accepted).filter(
        (data) => data?.meeting?.day === "Day 1" && data?.meeting?.start_time
      ),
      ...sortObjectsByTime(accepted).filter(
        (data) => data?.meeting?.day === "Day 2" && data?.meeting?.start_time
      ),
      ...sortObjectsByTime(accepted).filter((data) => !data?.meeting),
    ];
    setAcceptedData(acceptedSorted);
    setDeclinedData(declined);
  }, [eventLoading, invitationLoading, invitations]);

  const { data: meetingSlots } = useGetSlotsByTypeAndNoMeetingsQuery({
    meetingType,
    eventId: id,
    // senderId: currentAttendees?.sender?._id,
    // receiverId: currentAttendees?.receiver?._id,
  });
  const [slotModal, setSlotModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [userDataModal, setUserDataModal] = useState(null);
  const [globalLoading, setGLobalLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [rejectedId, setRejectedIdId] = useState("");

  console.log(meetingSlots, "meetingSlots");
  console.log(currentAttendees, "currentAttendees");

  const handleInviteAction = (data, action) => {
    if (action === "reject") {
      setRejectedIdId(data?._id);
    } else {
      setLoadingId(data?._id);
    }
    updateInvite(data, action);
  };

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };
  const onChangeStatus = (key) => {
    setSelectedStatusKey(key);
  };

  const undoMeeting = async (data) => {
    const date = dayjs(dayjs().format());
    const diff = date.diff(data.created_at, "minute");
    if (diff < 2) {
      const deleted = await deletInvitation({ id: data?._id });
      refetchInvitation();
    } else {
      message.error("You cannot undo this meeting.");
    }
  };

  const updateInvite = async (data, action) => {
    try {
      if (!data?._id) {
        message.error("Please ensure the invitation details are available.");
        return;
      }

      const inviteData = {
        meetingId: data?._id,
        isRejected: action === "reject",
      };

      const invite = await acceptInvite(inviteData);

      console.log(invite, "inviteResponse");

      if (invite?.error) {
        message.error(
          invite.error.data?.error || `Failed to ${action} the invite.`
        );
        return;
      }
      refetchMeeting();

      setSlotModal(false); // Close the modal after a successful update
      message.success(`Invite ${action}ed successfully`);
    } catch (err) {
      console.error(`Error ${action}ing invite:`, err);
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  const columns = [
    {
      title: "Meeting",
      dataIndex: "meeting",
      key: "meeting",
      render: (text, data) =>
        data?.receiver?.user?._id === getUserInfo().id ? (
          <p className="text-center font-medium">
            <NavLink
              onClick={() => {
                setDetailsModal(true);
                setUserData(data?.sender || data?.receiver);
              }}
              className="font-bold underline hover:!text-primary"
            >
              {data?.sender?.user?.first_name} {data?.sender?.user?.last_name}{" "}
            </NavLink>
            is requesting a meeting with you
          </p>
        ) : (
          <p className="text-center font-medium">
            You requested a meeting with{" "}
            <NavLink
              onClick={() => {
                setDetailsModal(true);
                setUserData(data?.receiver);
              }}
              className="font-bold underline hover:!text-primary"
            >
              {data?.receiver?.user?.first_name}{" "}
              {data?.receiver?.user?.last_name}{" "}
            </NavLink>
          </p>
        ),
    },
    {
      title: "Meeting Time",
      dataIndex: "meetingtime",
      key: "meetingtime",
      width: "20%",
      render: (text, data) => (
        <p className="text-center font-medium">
          {data?.slot?.startTime
            ? `${data?.slot?.startTime} - ${data?.slot?.endTime} (${data?.slot?.day})`
            : "----"}
        </p>
      ),
    },
    {
      title: "Table Number",
      dataIndex: "table_number",
      key: "table_number",
      width: "15%",
      render: (text, data) => {
        const tableNumber =
          data?.sender?.user?.metadata?.atendee_details?.table_number ||
          data?.receiver?.user?.metadata?.atendee_details?.table_number;

        return <p className="text-center font-medium">{tableNumber || "-"}</p>;
      },
    },

    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "5%",
      render: (text, data) => (
        <p className="text-center font-medium">
          <NavLink
            to={`/chats?user=${data?.sender?.user?._id === getUserInfo().id
              ? data?.receiver?.user?._id
              : data?.sender?.user?._id
              }&id=${id}`}
          >
            <Tooltip title={data.note} color={"#e2b025"}>
              <MessageOutlined style={{ fontSize: 20, cursor: "pointer" }} />
            </Tooltip>
          </NavLink>
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "25%",
      render: (text, data) => (
        <div className="flex justify-center flex-col md:flex-row">
          {data?.receiver?.user?._id === getUserInfo().id &&
            data.status === "pending" ? (
            <div
              className="flex justify-center flex-col md:flex-row"
              onClick={() => setCurrentAttendees(data)}
            >
              <ThemeButton
                onClick={() => handleInviteAction(data, "accept")}
                className={"ml-4 w-35 !bg-themeGreen"}
                content={
                  (loadingId === data?._id && inviteAcceptLoading) ||
                    meetingloading ? (
                    <Spin className="pl-7 pr-7" size="small" />
                  ) : (
                    "Accept"
                  )
                }
                disable={
                  (loadingId === data?._id && inviteAcceptLoading) ||
                  meetingloading
                }
              />
              <ThemeButton
                className={"ml-4 w-35 !bg-themeRed"}
                content={
                  (rejectedId === data?._id && inviteAcceptLoading) ||
                    meetingloading ? (
                    <Spin className="pl-7 pr-7" size="small" />
                  ) : (
                    "Reject"
                  )
                }
                disable={
                  (rejectedId === data?._id && inviteAcceptLoading) ||
                  meetingloading
                }
                onClick={() => handleInviteAction(data, "reject")}
              />
            </div>
          ) : (
            <div
              className={`h-9 w-24 md:w-32 ${data.sender?.user?._id === getUserInfo().id &&
                data.status === "pending"
                ? "bg-primary"
                : data.status === "accepted"
                  ? "!bg-themeGreen"
                  : data.status === "rejected"
                    ? "!bg-themeRed"
                    : "bg-primary"
                } rounded-lg flex justify-center items-center`}
            >
              <p className="font-semibold text-white capitalize">
                {data.sender?.user?._id === getUserInfo().id &&
                  data.status === "pending"
                  ? "Pending"
                  : data.status}
              </p>
            </div>
          )}
        </div>
      ),
    },
    // ...(selectedStatusKey !== "2"
    //   ? [
    //       {
    //         title: "Undo",
    //         dataIndex: "undo",
    //         key: "undo",
    //         width: "5%",
    //         render: (text, data) => (
    //           <div className="text-center">
    //             <UndoOutlined
    //               onClick={() => undoMeeting(data)}
    //               style={{ fontSize: "22px", cursor: "pointer" }}
    //             />
    //           </div>
    //         ),
    //       },
    //     ]
    //   : []),
  ];

  const statusItems = [
    {
      key: "1",
      label: (
        <p className={`${selectedStatusKey === "1" && "font-semibold"}`}>
          Pending
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterPending} />,
    },
    {
      key: "2",
      label: (
        <p className={`${selectedStatusKey === "2" && "font-semibold"}`}>
          Accepted
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterAccepted} />,
    },
    {
      key: "3",
      label: (
        <p className={`${selectedStatusKey === "3" && "font-semibold"}`}>
          Rejected
        </p>
      ),
      children: <ThemeTable columns={columns} data={filterRejected} />,
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>
          Event Info
        </p>
      ),
      children: <EventInfo event={upcomingEvent} />,
    },
    {
      key: "2",
      label: (
        <p className={`${selectedKey === "2" && "font-semibold"}`}>
          Event Agenda
        </p>
      ),
      children: (
        <EventAgenda
          isAdmin={false}
          eventID={id}
          eventName={upcomingEvent?.name}
          dontShowMeetings={true}
        />
      ),
    },
    {
      key: "3",
      label: (
        <p className={`${selectedKey === "3" && "font-semibold"}`}>
          List of Attendees
        </p>
      ),
      children: (
        <Attendees
          invitations={invitations}
          refetchInvitation={refetchMeeting}
          currentUser={getUserInfo()}
          attendees={upcomingEvent?.attendees}
          meetings={meetingsData?.meetings}
        />
      ),
    },
    {
      key: "4",
      label: (
        <p className={`${selectedKey === "4" && "font-semibold"}`}>
          Meeting Itinerary <Badge count={meetingNotifications.length} />
        </p>
      ),
      children: (
        <>
          <div>
            {getUserInfo().type === "sponsor" &&
              currentAttendee &&
              Object?.keys(currentAttendee)?.length ? (
              <Row className="py-4">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Alert
                    message="Important Note"
                    description={
                      <>
                        <span>
                          I. The time of your meetings will be sorted in
                          chronological order in your personalized agenda
                        </span>
                        <br />
                        <span>
                          II. A maximum {currentAttendee?.allowed_meetings}{" "}
                          meetings can be allocated to specific time in the
                          networking breaks. For the rest of the accepted
                          meetings, please ask the onsite staff to arrange it in
                          cocktail reception or over lunch reception
                        </span>
                      </>
                    }
                    type="warning"
                  />
                </Col>
              </Row>
            ) : null}
            <Row className="bg-white rounded-xl p-5">
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className="flex flex-col items-center mt-3 lg:mt-0"
              >
                <p className="font-semibold text-base md:text-lg">
                  Total Invites
                </p>
                <div className="bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                  <p className="font-bold text-white text-base">
                    {meetingsData?.meetings?.length || 0}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className="flex flex-col items-center"
              >
                <p className="font-semibold text-base md:text-lg">
                  Pending Invites
                </p>
                <div className="bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                  <p className="font-bold text-white text-base">
                    {filterPending?.length || 0}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className="flex flex-col items-center mt-3 md:mt-0"
              >
                <p className="font-semibold text-base md:text-lg">
                  Accepted Invites
                </p>
                <div className="bg-themeGreen w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                  <p className="font-bold text-white text-base">
                    {filterAccepted?.length || 0}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className="flex flex-col items-center mt-3 lg:mt-0"
              >
                <p className="font-semibold text-base md:text-lg">
                  Rejected Invites
                </p>
                <div className="bg-themeRed w-12 h-8 flex justify-center items-center rounded-lg mt-1">
                  <p className="font-bold text-white text-base">
                    {filterRejected?.length || 0}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <Tabs
            defaultActiveKey="1"
            items={statusItems}
            onChange={onChangeStatus}
            className="mt-2"
          />
        </>
      ),
    },
    {
      key: "5",
      label: (
        <p className={`${selectedKey === "5" && "font-semibold"}`}>
          Personalized Agenda
        </p>
      ),
      children: (
        <PersonalAgenda
          eventName={upcomingEvent?.name}
          eventId={upcomingEvent?._id}
          schedules={upcomingEvent?.schedules || []}
          isOtherNetworking={true}
          currentAttendee={currentAttendee?.user}
          meetings={sortAll}
          eventDate={{
            start_date: event?.events?.data[0]?.start_date,
            end_date: event?.events?.data[0]?.end_date,
          }}
        />
      ),
    },
    {
      key: "6",
      label: (
        <p className={`${selectedKey === "5" && "font-semibold"}`}>
          Event Booklet
        </p>
      ),
      children: (
        <EventBooklet
          eventName={upcomingEvent?.name}
          eventID={id}
          isUser={true}
        />
      ),
    },
    // {
    //   key: "6",
    //   label: (
    //     <p className={`${selectedKey === "6" && "font-semibold"}`}>
    //       Support Ticket
    //     </p>
    //   ),
    //   children: <SupportTicket eventId={upcomingEvent?._id} />,
    // },
  ];

  console.log(meetingloading, "meetingloading");
  if (globalLoading || eventLoading || invitationLoading || meetingloading) {
    <Spinner />;
  }
  // if (globalLoading || meetingloading) return <SpinnerModal />;
  return (
    <>
      <SecondContainer>
        <PersonalDetailsModal
          userData={userData}
          isModalOpen={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
        <div className="flex items-center">
          <NavLink to="/upcoming-events">
            <img src={left_arrow_icon} width={18} height={18} alt="" />
          </NavLink>
          <p className="ml-3 font-semibold text-lg md:text-2xl">
            {upcomingEvent?.name}
          </p>
        </div>
        <Tabs
          defaultActiveKey={searchParams.get("tab") || "1"}
          items={items}
          onChange={onChange}
          className="mt-2"
        />
      </SecondContainer>
    </>
  );
};

export default Details;
