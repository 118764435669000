import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import {
  Upload,
  DatePicker,
  Col,
  Drawer,
  Row,
  Input,
  Spin,
  Select,
} from "antd";
import {
  left_arrow_icon,
  add_icon,
  upload_icon,
  calender_colored_icon,
} from "../../assets/icons/icons";
import { uploadImage } from "../../helpers/helpers";
import { ThemeButton, ThemeInput } from "../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const { Dragger } = Upload;

const validationSchema = yup.object().shape({
  eventName: yup.string().required("Event name is required"),
  location: yup.string().required("Event location is required"),
  start_date: yup.string().nullable().required("Start date is required"),
  end_date: yup.string().nullable().required("End date is required"),
  event_hall: yup.string().required("Event hall selection is required"),
  discussion: yup
    .number()
    .typeError("Discussion details must be a number")
    .required("Discussion details are required"),
  speakers: yup
    .number()
    .typeError("Speakers information must be a number")
    .required("Speakers information is required"),
  meetings: yup
    .number()
    .typeError("Meetings details must be a number")
    .required("Meetings details are required"),
  networking_hours: yup
    .number()
    .typeError("Networking hours must be a number")
    .required("Networking hours are required"),
  booths: yup
    .number()
    .typeError("Exhibition booths information must be a number")
    .required("Exhibition booths information is required"),
  description: yup.string().required("Event description is required"),
});

const EventDrawer = (
  {
    open,
    btnTxt,
    addIcon,
    isLoading,
    formData,
    setFormData,
    image,
    setImage,
    onClose,
    resetForm,
    onSubmit,
  },
  props
) => {
  const [uploading, setUploading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
    mode: "onChange", // Validate on every input change
    shouldFocusError: true,
  });

  console.log(formData, "formData");
  const handleImage = async (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      setUploading(true);
      const res = await uploadImage(info.file.originFileObj);
      setImage(res);
      setFormData({
        ...formData,
        banner: res,
      });

      setValue("banner", res);
    }
  };

  useEffect(() => {
    if (resetForm) {
      reset();
    }
  }, [resetForm]);
  return (
    <Drawer
      width={450}
      title={btnTxt}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt="" width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={handleSubmit(onSubmit)}
          className={"ml-4 w-32"}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <div>
        <p className="font-semibold mb-2">Event Banner</p>
        {image ? (
          <img src={image} alt="" />
        ) : (
          <>
            {uploading ? (
              <div className="flex justify-center">
                <Spin />
              </div>
            ) : (
              <Dragger
                name="file"
                multiple={false}
                showUploadList={false}
                onChange={handleImage}
              >
                <div className="flex justify-center mb-1">
                  <img src={upload_icon} alt="" width={40} />
                </div>
                <p className="">Drag and drop your file</p>
                <p className="">OR</p>
                <div className="flex justify-center mt-1">
                  <ThemeButton content={"Browse File"} />
                </div>
              </Dragger>
            )}
          </>
        )}
      </div>
      <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={24} className="mb-4">
          <Controller
            control={control}
            name="eventName"
            render={({ field: { onChange, value, ref } }) => (
              <ThemeInput
                label="Event Name"
                placeholder="Event Name"
                error={errors.eventName?.message}
                value={value}
                onChange={onChange}
                inputRef={ref}
              />
            )}
          />
          {errors.name?.message && (
            <p className="error-text text-[#e94646]">{errors.name?.message}</p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">Start Date</p>
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                className="w-full h-10 font-medium"
                suffixIcon={
                  <img src={calender_colored_icon} alt="" width={20} />
                }
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
          {errors.start_date && (
            <p className="error-text text-[#e94646]">
              {errors.start_date.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">End Date</p>
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                className="w-full h-10 font-medium"
                suffixIcon={
                  <img src={calender_colored_icon} alt="" width={20} />
                }
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
          {errors.end_date && (
            <p className="error-text text-[#e94646]">
              {errors.end_date.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Event Location"}
                placeholder={"Event location"}
              />
            )}
          />
          {errors.location?.message && (
            <p className="error-text text-[#e94646]">
              {errors.location?.message}
            </p>
          )}
        </Col>
        <Col span={12}>
          <p className="font-semibold mb-1">Event Hall</p>
          <Controller
            name="event_hall"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select Event Hall"
                className="w-full font-semibold"
                size="large"
                onChange={(value) => field.onChange(value)}
                // defaultValue={"multi-hall"}
              >
                <Select.Option value="single-hall">
                  Single Hall Event
                </Select.Option>
                <Select.Option value="multi-hall">
                  Multi Hall Event
                </Select.Option>
              </Select>
            )}
          />
          {errors.event_hall && (
            <p className="error-text text-[#e94646]">
              {errors.event_hall.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="discussion"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Panel Discussions"}
                placeholder={"Panel Discussions"}
                error={errors.discussion?.message}
              />
            )}
          />
          {errors.discussion?.message && (
            <p className="error-text text-[#e94646]">
              {errors.discussion?.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="speakers"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Speakers"}
                placeholder={"Speakers"}
                error={errors.speakers?.message}
              />
            )}
          />
          {errors.speakers?.message && (
            <p className="error-text text-[#e94646]">
              {errors.speakers?.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="meetings"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Meetings"}
                placeholder={"Meetings"}
                error={errors.meetings?.message}
              />
            )}
          />
          {errors.meetings?.message && (
            <p className="error-text text-[#e94646]">
              {errors.meetings?.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="networking_hours"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Networking Hours"}
                placeholder={"Networking Hours"}
                error={errors.networking_hours?.message}
              />
            )}
          />
          {errors.networking_hours?.message && (
            <p className="error-text text-[#e94646]">
              {errors.networking_hours?.message}
            </p>
          )}
        </Col>
        <Col span={12} className="mb-4">
          <Controller
            name="booths"
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                label={"Exhibition Booths"}
                placeholder={"Exhibition Booths"}
                error={errors.booths?.message}
              />
            )}
          />
          {errors.booths?.message && (
            <p className="error-text text-[#e94646]">
              {errors.booths?.message}
            </p>
          )}
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1">Event Description</p>

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                label={"Event Description"}
                name="description"
                placeholder="Event Description"
                rows={5}
                className="font-medium pt-2"
              />
            )}
          />
          {errors.description?.message && (
            <p className="error-text text-[#e94646]">
              {errors.description?.message}
            </p>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default EventDrawer;
