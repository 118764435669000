import { Modal, Row, Col, Card, Statistic, Space } from "antd";

const ShowStatsModal = ({ isModalOpen, onClose, stats, user }) => {
  console.log(stats, "statsstats");
  const { events, meetings } = stats || {};
  return (
    <Modal
      width={800}
      title={`${user?.first_name} ${user?.last_name} Stats`}
      footer={null}
      visible={isModalOpen}
      onCancel={onClose}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Card
            title={`Participated in ${events?.length} ${
              events?.length > 1 ? "events" : "event"
            }`}
          >
            <ul>
              {events?.map((eventName, index) => (
                <li className="font-regular my-2" key={index}>{`${
                  index + 1
                }) ${eventName}`}</li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={12}>
          {meetings ? (
            <Card title="Invitations">
              <Statistic title="Total Invitations" value={meetings?.length} />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Statistic
                  title="Accepted Invitations"
                  value={
                    meetings?.filter((invite) => invite.status === "accepted")
                      ?.length
                  }
                />
                <Statistic
                  title="Pending Invitations"
                  value={
                    meetings?.filter((invite) => invite.status === "pending")
                      ?.length
                  }
                />
              </Space>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
};

export default ShowStatsModal;
