// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Input: {
      variants: {
        yellow: {
          field: {
            color: 'black', // Text color inside the input
            borderColor: 'yellow.500', // Border color
            _focus: {
              borderColor: 'yellow.600', // Border color when focused
              boxShadow: '0 0 0 1px yellow.600', // Shadow color when focused
            },
          },
        },
      },
    },
  },
});

export default theme;
