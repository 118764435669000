import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants/constants'
import { getCurrentToken, getValueFromStorage } from '../helpers/helpers'

export const InvitationApi = createApi({
  reducerPath: 'invitation',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      headers.set('Content-type', 'application/json')
      headers.set('authorization', `Bearer ${getCurrentToken()}`)
      return headers
    }
  }),
  endpoints: builder => ({
    getInvitation: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        eventId = '',
        sender = '',
        receiver = '',
        status = ''
      }) => ({
        url: 'api/invitation',
        method: 'GET',
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
          event_id: eventId,
          sender,
          receiver,
          status
        }
      })
    }),
    getEmptySlots: builder.query({
      query: ({ eventId = '', sender = '', receiver = '' }) => ({
        url: `api/invitation/emptySlots?`,
        method: 'GET',
        params: {
          eventId,
          sender,
          receiver
        }
      })
    }),
    addInvitation: builder.mutation({
      query: body => ({
        url: `api/invitation`,
        method: 'POST',
        body: body
      })
    }),
    putInvitation: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/invitation/${id}`,
          method: 'PUT',
          body: body
        }
      },
      invalidatesQueries: [{ queryFn: 'getInvitation' }] // Invalidate the getInvitation query
    }),
    deleteInvitation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/invitation/${id}`,
          method: 'DELETE'
        }
      }
    })
  })
})

export const {
  useGetInvitationQuery,
  useLazyGetInvitationQuery,
  useAddInvitationMutation,
  usePutInvitationMutation,
  useLazyGetEmptySlotsQuery,
  useDeleteInvitationMutation
} = InvitationApi
