import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  Dashboard,
  Logs,
  Event,
  EventDetails,
  ProfileDetails,
  Sponsors,
  SponsorsDetails,
  Delegates,
  Tickets,
  Login,
  UpcomingEvents,
  Details,
  Chats,
  UpdateProfile,
  SupportStaff,
  Announcements,
} from "../../pages/pages";
import { Result, message } from "antd";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  const [loading, setLoading] = useState(true);

  const checkAuth = () => {
    // First, try to get userType from sessionStorage
    let userType = sessionStorage.getItem("userType");
    let token = null;

    // Check sessionStorage for tokens for non-admin user types
    if (userType && userType !== "admin") {
      token = sessionStorage.getItem(`${userType}Token`);
    }

    // If no token found in sessionStorage or userType is 'admin', check localStorage
    if (!token) {
      // Fallback to localStorage if sessionStorage does not have a valid token
      userType = localStorage.getItem("userType");
      if (userType) {
        token = localStorage.getItem(`${userType}Token`);
      }
    }
    const user = localStorage.getItem("user");

    return { user, userType, token };
  };

  const ProtectedRoute = ({ children, allowedTypes }) => {
    const { userType } = checkAuth();

    if (!userType) {
      return <Navigate to="/" />;
    }

    // If userType is not allowed, redirect to login
    if (allowedTypes && !allowedTypes.includes(userType)) {
      return <Navigate to="/" />;
    }

    return children;
  };


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Login
              setAuthUser={(auth, type) => {
                localStorage.setItem("user", auth);
                localStorage.setItem("userType", type);
              }}
            />
          }
        />

        {/* Admin Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logs"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Logs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Event />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event/:id"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <EventDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:user_type/profile/:user_id/:event_id"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <ProfileDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sponsors"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Sponsors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:category/details"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <SponsorsDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delegates"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Delegates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tickets"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Tickets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support-staff"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <SupportStaff />
            </ProtectedRoute>
          }
        />
        <Route
          path="/announcements"
          element={
            <ProtectedRoute allowedTypes={["admin"]}>
              <Announcements />
            </ProtectedRoute>
          }
        />

        {/* Delegate & Sponsor Routes */}
        <Route
          path="/update-profile"
          element={
            <ProtectedRoute allowedTypes={["delegate", "sponsor"]}>
              <UpdateProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upcoming-events"
          element={
            <ProtectedRoute allowedTypes={["delegate", "sponsor"]}>
              <UpcomingEvents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upcoming-events/details/:id"
          element={
            <ProtectedRoute allowedTypes={["delegate", "sponsor"]}>
              <Details />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chats"
          element={
            <ProtectedRoute allowedTypes={["delegate", "sponsor"]}>
              <Chats />
            </ProtectedRoute>
          }
        />

        {/* 404 Page */}
        <Route
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
