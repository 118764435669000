import {
  SecondContainer,
  ThemeButton,
  ThemeInput,
} from "../../components/components";
import {
  Layout,
  Menu,
  theme,
  Avatar,
  List,
  Skeleton,
  Badge,
  Empty,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { user } from "../../assets/images/images";
import { left_arrow_icon } from "../../assets/icons/icons";
import { useSearchParams, NavLink, useNavigate } from "react-router-dom";
import {
  useGetChatQuery,
  useAddChatMutation,
  useUpdateChatMutation,
} from "../../services/chat";
import {
  useGetMessageQuery,
  useAddMessageMutation,
} from "../../services/message";
import { getUserInfo } from "../../helpers/helpers";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { socket } from "../../config/socket";
dayjs.extend(relativeTime);

const { Header, Content, Footer, Sider } = Layout;

const Chats = () => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  let [searchParams, setSearchParams] = useSearchParams();

  const [messageLimit, setMessageLimit] = useState(10);
  const {
    data: chats,
    error,
    isLoading: chatsLoading,
    refetch: refetchChats,
  } = useGetChatQuery({
    user: getUserInfo().id,
    event: getUserInfo().event,
  });

  const {
    data: messages,
    error: messagesError,
    isLoading: messagesLoading,
    refetch: refetchMessages,
  } = useGetMessageQuery({
    result_per_page: messageLimit,
    sender: getUserInfo().id,
    receiver: searchParams.get("user"),
  });
  const [addChat, { isLoading: chatAddLoading }] = useAddChatMutation();
  const [updateChat, { isLoading: chatupdateLoading }] =
    useUpdateChatMutation();
  const [addMessage, { isLoading: messageAddLoading }] =
    useAddMessageMutation();
  const [chatUsers, setChatUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const chatBox = useRef(null);
  const childRef = useRef(null);

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const chatAdd = async () => {
    try {
      await addChat({
        sender: getUserInfo().id,
        receiver: searchParams.get("user"),
        event: getUserInfo().event,
      });
      refetchChats();
    } catch (err) {
      console.log(err);
    }
  };

  const send_message = async () => {
    const chatId =
      getUserInfo().id < searchParams.get("user")
        ? `${getUserInfo().id}${searchParams.get("user")}`
        : `${searchParams.get("user")}${getUserInfo().id}`;
    const data = {
      message,
      sender: getUserInfo().id,
      receiver: searchParams.get("user"),
      eventId: getUserInfo().event,
    };
    const addedMessage = await addMessage(data);
    console.log("addedMessage->", addedMessage);
    const chatList = chats?.chats?.data || [];
    const index = chatList.findIndex((v) => v.chat_id === chatId);
    const oldMessages = [...chatMessages];
    const socketData = {
      ...addedMessage.data.message,
      receiver:
        chatList[index].receiver === searchParams.get("user")
          ? chatList[index].receiver
          : chatList[index].sender,
      sender:
        chatList[index].sender?._id === getUserInfo().id
          ? chatList[index].sender
          : chatList[index].receiver,
    };
    oldMessages.unshift(socketData);
    setMessage("");
    setChatMessages(oldMessages);
    chatBox.current.scroll({ top: chatBox.current.scrollHeight });
    socket.emit("message", socketData);
  };

  useEffect(() => {
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    const chatId =
      getUserInfo().id < searchParams.get("user")
        ? `${getUserInfo().id}${searchParams.get("user")}`
        : `${searchParams.get("user")}${getUserInfo().id}`;
    if (searchParams.get("user")) {
      socket.on(chatId, (data) => {
        const oldMessages = [...chatMessages];
        const index = oldMessages.findIndex((v) => v?._id === data?._id);
        if (index === -1) {
          oldMessages.unshift(data);
          setChatMessages(oldMessages);
          refetchChats();
        }
      });
    }
  }, [messagesLoading, chatMessages]);

  useEffect(() => {
    const messagesList = messages?.messages?.data || [];
    console.log("messagesList->", messagesList);
    if (searchParams.get("user")) {
      setChatMessages(messagesList);
      childRef.current.fetchUserData();
    }
  }, [messagesLoading, messages]);

  useEffect(() => {
    const chatList = chats?.chats?.data || [];
    console.log("chatList", chatList);
    setChatUsers(
      chatList
        ?.filter(
          (v) =>
            v.last_message ||
            v.receiver?._id === searchParams.get("user") ||
            v.sender?._id === searchParams.get("user")
        )
        ?.map((v) => {
          const receiver = getUserInfo().id == v?.receiver?._id;
          return getItem(
            <div className="flex justify-between items-center">
              <div>
                <p
                  className={`${
                    v?.last_message_read
                      ? "font-semibold"
                      : "font-bold underline"
                  }  text-base`}
                >
                  {v[receiver ? "sender" : "receiver"]?.first_name}{" "}
                  {v[receiver ? "sender" : "receiver"]?.last_name}
                </p>
                <p className={`font-semibold text-xs`}>{v.last_message}</p>
              </div>
              <div
                className={`${
                  !v?.last_message_read ? "flex" : "hidden"
                } w-2 h-2 rounded-full bg-primary`}
              ></div>
            </div>,
            receiver ? v?.sender?._id : v?.receiver?._id,
            <img
              className="w-[50px] h-[50px] rounded-full object-cover"
              src={v[receiver ? "sender" : "receiver"]?.picture || user}
              width={50}
              height={50}
              alt=""
            />
          );
        })
    );
    const chatId =
      getUserInfo().id < searchParams.get("user")
        ? `${getUserInfo().id}${searchParams.get("user")}`
        : `${searchParams.get("user")}${getUserInfo().id}`;
    const index = chatList.findIndex((v) => v.chat_id === chatId);
    if (index === -1 && !chatsLoading && searchParams.get("user")) {
      chatAdd();
    }
    console.log("chatId->", chatId);
    console.log("searchParams.get user", searchParams.get("user"));
  }, [chats, chatsLoading]);

  const loadMoreMessages = () => {
    setMessageLimit(messageLimit + 10);
    refetchMessages();
  };
  const previous = localStorage.getItem("chat-previous");

  return (
    <SecondContainer ref={childRef}>
      <div>
        <NavLink to={previous} className="flex items-center mb-2">
          <img src={left_arrow_icon} width={16} height={16} alt="" />
          <p className="ml-3 font-semibold text-1xl">Back</p>
        </NavLink>
      </div>
      {chatUsers.length ? (
        <Layout hasSider>
          <Sider
            width={300}
            style={{
              height: "80vh",
              overflow: "scroll",
              background: "#fff",
            }}
          >
            <Menu
              onClick={(e) => {
                const chatID = chats?.chats?.data?.find((v) => {
                  if (v.receiver?._id === e?.key || v.sender?._id === e?.key) {
                    return v;
                  }
                });
                setSearchParams({
                  user: e.key,
                  id: searchParams.get("id"),
                });
                updateChat(chatID?._id);
                refetchChats();
                refetchMessages();
              }}
              mode="inline"
              defaultSelectedKeys={[
                searchParams.get("user"),
                searchParams.get("id"),
              ]}
              items={chatUsers}
            />
          </Sider>
          {searchParams.get("user") ? (
            <Layout
              className="site-layout"
              style={{
                background: "#fff",
                padding: "10px 30px 30px 30px",
              }}
            >
              <Content
                onLoad={() =>
                  chatBox.current.scroll({ top: chatBox.current.scrollHeight })
                }
                ref={chatBox}
                style={{
                  height: "60vh",
                  overflow: "scroll",
                  position: "relative",
                }}
              >
                {messages?.messages?.total !==
                  messages?.messages?.data?.length &&
                messages?.messages?.data?.length ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      top: 10,
                      zIndex: 10,
                    }}
                  >
                    <span
                      onClick={loadMoreMessages}
                      style={{
                        background: "#e2b025",
                        color: "#fff",
                        borderRadius: 100,
                        padding: "5px 20px 5px 20px",
                        cursor: "pointer",
                      }}
                    >
                      Load more messages
                    </span>
                  </div>
                ) : null}
                <List
                  itemLayout="horizontal"
                  dataSource={[...chatMessages].reverse()}
                  renderItem={(item, index) =>
                    messagesLoading ? (
                      <List.Item>
                        <div className="bg-white rounded-xl p-5 mb-8">
                          <Skeleton />
                        </div>
                      </List.Item>
                    ) : (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar src={item?.sender?.picture || user} />
                          }
                          title={
                            <span className="font-bold">
                              {item?.sender?.first_name}{" "}
                              {item?.sender?.last_name}
                            </span>
                          }
                          description={item.message}
                        />
                        <div className="text-[12px]">
                          {dayjs(new Date(item.created_at)).fromNow()}
                        </div>
                      </List.Item>
                    )
                  }
                />
              </Content>
              <Footer
                style={{
                  textAlign: "center",
                  background: "#fff",
                  width: "100%",
                  padding: 0,
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex-1 mr-2">
                    <ThemeInput
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => e.keyCode === 13 && send_message()}
                      placeholder="Enter your message here..."
                    />
                  </div>
                  <ThemeButton
                    className="mt-1"
                    onClick={send_message}
                    content="SEND"
                  />
                </div>
              </Footer>
            </Layout>
          ) : null}
        </Layout>
      ) : (
        <div className="p-10">
          <Empty description="No chats available" />
        </div>
      )}
    </SecondContainer>
  );
};

export default Chats;
