import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const AuthApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    setLogin: builder.mutation({
      query: (body) => ({
        url: "api/user/login",
        method: "POST",
        body: body,
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: "api/user/register",
        method: "POST",
        body: body,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/user/update/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    getUserStats: builder.query({
      query: ({ id }) => {
        return {
          url: `api/user/stats/${id}`,
          method: "GET",
        };
      },
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/user/delete/${id}`,
          method: "DELETE",
        };
      },
    }),
    getUsers: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        userType = "",
        userId = "",
        search = "",
      }) => {
        return {
          url: "api/user",
          method: "GET",
          params: {
            current_page: currentPage,
            result_per_page: resultPerPage,
            user_type: userType,
            user_id: userId,
            search,
          },
        };
      },
    }),
    loginDashboard: builder.mutation({
      query: (body) => ({
        url: "api/user/login-dashboard",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useDeleteUserMutation,
  useSetLoginMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUserStatsQuery,
  useLoginDashboardMutation, // Export the new mutation hook
} = AuthApi;
