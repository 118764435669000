import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { CLOUDINARY_URL } from "../constants/constants";

const uploadImage = async (image, fileName) => {
  let url;
  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", fileName ? fileName : "ztqqlkiz");
  data.append("cloud_name", "dxsowbvlv");
  await fetch(CLOUDINARY_URL, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((data) => {
      url = data.secure_url;
    })
    .catch((err) => {
      err = err;
    });
  return url;
};

// Add key to local storage with user type-based keys
const addKeyToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

// Retrieve the key based on user type
export const getCurrentToken = () => {
  const userType =
    sessionStorage.getItem("userType") || localStorage.getItem("userType");

  if (!userType) {
    return null; // Return null if no userType is found
  }

  const tokenKey = userType === "admin" ? "adminToken" : `${userType}Token`;

  return (
    sessionStorage.getItem(tokenKey) || localStorage.getItem(tokenKey) || null
  );
};

export const deleteCurrentToken = () => {
  const userTypeLocal = localStorage.getItem("userType");
  const userTypeSession = sessionStorage.getItem("userType");

  if (userTypeLocal === "admin" && userTypeSession) {
    sessionStorage.clear();

    return;
  }

  if (userTypeLocal) {
    localStorage.clear();
  }
};

const deleteKeyFromStorage = (key) => {
  localStorage.removeItem(key);
};

const updateKey = (key, value) => {
  localStorage.setItem(key, value);
};

const getValueFromStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

const getUserInfo = () => {
  try {
    const token = getCurrentToken();

    if (!token) {
      throw new Error("No token found");
    }

    const user = jwt_decode(token);
    return user;
  } catch (err) {
    console.error("Error decoding token:", err);
    return null;
  }
};

const showDeletePopup = (onConfirmed) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't to delete it!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#FF0000",
    cancelButtonColor: "#DBDADA",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirmed();
    }
  });
};

export {
  addKeyToStorage,
  deleteKeyFromStorage,
  updateKey,
  getValueFromStorage,
  getUserInfo,
  uploadImage,
  showDeletePopup,
};
