import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken } from "../helpers/helpers"; // Make sure this helper function exists

export const NewMeetingApi = createApi({
  reducerPath: "meetings",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  tagTypes: ["Meeting"], // Define the tag type
  endpoints: (builder) => ({
    getMeetings: builder.query({
      query: (data) => ({
        url: "api/meeting/get-All", // Adjusted to match /get-All
        method: "GET",
        params: {
          ...data,
        },
      }),
      providesTags: ["Meeting"], // Provide the Meeting tag
    }),
    getMeetingsByUserId: builder.query({
      query: (body) => ({
        url: `api/meeting/get-meetings-by-userid`, // Matches the /get-meetings-by-userid/:userId route
        method: "GET",
        params: { ...body },
      }),
      providesTags: (result, error, userId) => [
        { type: "Meeting", id: userId },
      ],
    }),
    getUserEventsAndMeetings: builder.query({
      query: (id) => ({
        url: `api/meeting/get-events-and-meetings/${id}`, // Matches the /get-meetings-by-userid/:userId route
        method: "GET",
      }),
    }),
    getMeetingById: builder.query({
      query: (id) => ({
        url: `api/meeting/${id}`, // Matches the /get-by-id/:id route
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Meeting", id }],
    }),
    putMeetings: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/meeting/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Meeting", id }],
    }),
    putMultipleMeetings: builder.mutation({
      query: (body) => ({
        url: "api/meeting/update/multiple",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meeting"], // Invalidate all meetings
    }),
    addMeetings: builder.mutation({
      query: (body) => ({
        url: "api/meeting",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Meeting"], // Invalidate all meetings
    }),
    deleteMeetings: builder.mutation({
      query: (id) => ({
        url: `api/meeting/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Meeting"], // Invalidate all meetings
    }),
  }),
});

export const {
  useAddMeetingsMutation,
  usePutMeetingsMutation,
  usePutMultipleMeetingsMutation,
  useDeleteMeetingsMutation,
  useGetMeetingsQuery,
  useGetMeetingsByUserIdQuery,
  useLazyGetUserEventsAndMeetingsQuery,
  useGetMeetingByIdQuery,
  useLazyGetMeetingsQuery,
} = NewMeetingApi;
