import { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { Card, Tabs } from "antd";
import { useGetMeetingsQuery } from "../../../services/newMeeting";

const { TabPane } = Tabs;

const SponsorAdditionalMeetings = ({ eventID }) => {
  const [slotsData, setSlotsData] = useState({
    meetingType: "sponsor-to-all",
    eventId: eventID,
    day: "",
  });

  const {
    data: slots,
    isLoading: slotFetchingLoading,
    refetch,
  } = useGetMeetingsQuery(slotsData);

  if (slotFetchingLoading) return <Spinner />;
  if (!slots) return null;

  const handleTabChange = (key) => {
    setSlotsData((prevData) => ({
      ...prevData,
      meetingType: key,
    }));

    refetch();
  };

  // Filter only additional meetings and group them by sender ID
  const additionalMeetings = slots?.meetings?.filter(
    (meeting) => meeting.isAdditional === true
  );

  console.log(additionalMeetings, "additionalMeetings");

  // Grouping meetings by sender ID
  const groupedMeetings = additionalMeetings?.reduce((acc, meeting) => {
    const senderId = meeting.sender._id;
    if (!acc[senderId]) {
      acc[senderId] = [];
    }
    acc[senderId].push(meeting);
    return acc;
  }, {});
  console.log(groupedMeetings, "groupedMeetings");

  const CardComponent = () => {
    if (!additionalMeetings?.length) {
      return <div>No additional meetings found.</div>;
    }
    return (
      <div className="flex flex-wrap">
        {Object.entries(groupedMeetings).map(([senderId, meetings]) => (
          <div key={senderId}>
            <Card
              className="m-4"
              title={`${meetings[0]?.sender.user?.first_name || "N/A"} ${
                meetings[0].sender.user?.last_name || ""
              }`}
              bordered={false}
              style={{ width: 520 }}
            >
              <div className="flex justify-between mb-4">
                <span className="w-1/12 font-normal text-left"></span>
                <span className="w-4/12 font-semibold text-left">Sender</span>
                <span className="w-4/12 font-semibold text-center">
                  Receiver
                </span>
                <span className="font-semibold text-right w-3/12">
                  T.Number
                </span>
                <span className="w-1/12 font-normal text-center"></span>
                <span className="w-1/12 font-normal text-center"></span>
              </div>

              {meetings
                .filter(meeting => meeting.sender && meeting.receiver)
                .map((meeting, index) => (
                  <div
                    key={meeting._id}
                    className="bg-white flex border-b min-h-9 justify-between my-1 py-1 px-1"
                  >
                    <span className="w-1/12 font-normal text-left">
                      {index + 1}
                    </span>
                    <span className="w-3/12 font-normal text-left">
                      {`${meeting.sender.user?.first_name || "N/A"} ${
                        meeting.sender.user?.last_name || ""
                      }`}
                    </span>
                    <span className="w-4/12 font-normal text-center">
                      {`${meeting.receiver.user?.first_name || "N/A"} ${
                        meeting.receiver.user?.last_name || ""
                      }`}
                    </span>
                    <span className="font-normal text-center w-2/12">
                      {meeting.receiver.user.metadata?.atendee_details
                        ?.table_number ||
                        meeting.sender.user.metadata?.atendee_details
                          ?.table_number ||
                        "N/A"}
                    </span>
                    <span className="w-1/12 font-normal text-center"></span>
                    <span className="w-1/12 font-normal text-center"></span>
                  </div>
                ))}
            </Card>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="sponsor-to-all"
        onChange={handleTabChange}
        className="my-4"
      >
        <TabPane tab="Sponsor to All" key="sponsor-to-all">
          <CardComponent />
        </TabPane>
        <TabPane tab="Delegate to Delegate" key="delegate-to-all">
          <CardComponent />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SponsorAdditionalMeetings;
