import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const WishlistApi = createApi({
  reducerPath: "wishlists",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWishlists: builder.query({
      query: () => ({
        url: `api/wishlist`,
        method: "GET",
      }),
    }),
    updateWishlist: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/wishlist/${id}`,
        method: "PUT",
        body: body,
      }),
    }),
    addWishlist: builder.mutation({
      query: (body) => ({
        url: `api/wishlist`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetWishlistsQuery,
  useUpdateWishlistMutation,
  useAddWishlistMutation,
} = WishlistApi;
