import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  comment_colored_icon,
  events_white_icon,
} from "../../assets/icons/icons";
import { horizontal_logo, user } from "../../assets/images/images";
import { Dropdown, Badge } from "antd";
import {
  deleteCurrentToken,
  deleteKeyFromStorage,
  getUserInfo,
} from "../../helpers/helpers";
import { useGetUsersQuery } from "../../services/auth";
import { useGetMessageQuery } from "../../services/message"; // Add this import
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
const items = [
  {
    label: "Update Profile",
    key: "1",
  },
  {
    label: "Log Out",
    key: "2",
  },
];

const NavBar = forwardRef((_, ref) => {
  const { data, isLoading, refetch } = useGetUsersQuery({
    userId: getUserInfo().id,
  });

  const location = useLocation();

  // const id = params.id || searchParams.get("id");

  const [userData, setUserData] = useState({});
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();

  // Add this query to fetch messages
  const { data: messages, refetch: refetchMessages } = useGetMessageQuery({
    receiver: getUserInfo().id,
  });

  useImperativeHandle(ref, () => ({
    getUserData() {
      refetch();
      refetchMessages();
    },
  }));

  console.log(getUserInfo().event, "getUserInfo().event");

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      // deleteKeyFromStorage("token");
      deleteCurrentToken();
      deleteKeyFromStorage("message");
      navigate("/");
    } else if (e.key === "1") {
      navigate("/update-profile");
    }
  };
  useEffect(() => {
    setUserData(data?.users?.data[0]);
  }, [isLoading, data]);

  useEffect(() => {
    if (messages?.messages?.data) {
      const filterMess = messages?.messages?.data.filter(
        (item) =>
          item.receiver._id === getUserInfo().id &&
          item.eventId === getUserInfo().event
      );
      console.log(filterMess, "filterMess");
      setNotificationCount(filterMess?.length);
    }
  }, [messages]);

  const menuProps = {
    items: [
      {
        label: (
          <div>
            <span className="text-[18px]">{`${userData?.first_name} ${userData?.last_name}`}</span>{" "}
          </div>
        ),
      },
      ...items,
    ],
    onClick: handleMenuClick,
  };
  console.log(location);
  return (
    <div className="bg-white sticky top-0 z-10 flex items-center justify-between px-3 lg:px-16 md:px-12 sm:px-7 py-3">
      <div className="flex items-center">
        <img src={horizontal_logo} className="sm:w-44 w-36" alt="" />
        <div className="items-center hidden sm:flex ml-14">
          <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
            <img src={events_white_icon} width={15} height={15} alt="" />
          </div>
          <p className="ml-2 font-semibold text-2xl">Events</p>
        </div>
      </div>
      <NavLink to="/chats" className="sm:hidden">
        <Badge count={notificationCount}>
          <img src={comment_colored_icon} alt="" width={30} />
        </Badge>
      </NavLink>
      <div className="flex items-center">
        <NavLink
          onClick={() =>
            localStorage.setItem(
              "chat-previous",
              `${location.pathname}${location.search || ""}`
            )
          }
          to={`/chats${
            getUserInfo().event ? `?id=${getUserInfo().event}` : ""
          }`}
          className="sm:block hidden"
        >
          <Badge count={notificationCount}>
            <img src={comment_colored_icon} alt="" width={30} height={30} />
          </Badge>
        </NavLink>
        <Dropdown menu={menuProps} placement="bottomRight">
          <img
            src={userData?.picture || user}
            alt=""
            style={{ width: 50, height: 50, objectFit: "cover" }}
            className="sm:ml-14 cursor-pointer rounded-full object-cover"
          />
        </Dropdown>
      </div>
    </div>
  );
});

export default NavBar;
