import { useCallback, useEffect, useState } from "react";
import {
  sponsors_white_icon,
  add_icon,
  edit_icon,
  delete_icon,
  right_arrow_icon,
  eye_regular,
} from "../../assets/icons/icons";
import {
  Container,
  ThemeTable,
  SponsorDrawer,
  ThemeButton,
  ThemeInput,
} from "../../components/components";
import { user, userFemale } from "../../assets/images/images";
import {
  useGetUsersQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLazyGetUserStatsQuery,
} from "../../services/auth";
import { message } from "antd";
import ShowStatsModal from "../../components/showStatsModal/showStatsModal";
import { debounce } from "lodash";
import { useLazyGetUserEventsAndMeetingsQuery } from "../../services/newMeeting";

const Sponsors = () => {
  const [mode, setMode] = useState("create");
  const [editID, setEditID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [sponsorStats, setSponsorStats] = useState(null);
  const [showSponsorStatsModal, setShowSponsorStatsModal] = useState(null);
  const [registerUser, { isLoading: regestiring }] = useRegisterUserMutation();
  const [getUserStats] = useLazyGetUserStatsQuery();
  const [userInfo, setUserInfo] = useState(null);
  const [
    getUserEventsAndMeetings,
    { isLoading: getUserEventsAndMeetingsLoading },
  ] = useLazyGetUserEventsAndMeetingsQuery();
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation();
  const [search, setSearch] = useState("");
  const { data, error, isLoading, refetch } = useGetUsersQuery({
    currentPage,
    resultPerPage,
    userType: "sponsor",
    search,
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    picture: "",
    user_type: "sponsor",
    metadata: {},
  });
  const [image, setImage] = useState("");

  useEffect(() => {
    setTotal(data?.users?.total || 0);
  }, [isLoading]);

  const onClose = () => {
    setOpen(false);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      picture: "",
      user_type: "sponsor",
      metadata: {},
    });
    setImage("");
    setMode("create");
  };

  const onSubmit = async () => {
    let response;
    if (mode === "create") {
      response = await registerUser(formData);
    } else {
      response = await updateUser({ id: editID, body: formData });
    }
    if (response?.data?.status == 200) {
      message.success(
        `Sponsor ${mode === "create" ? "registered" : "updated"} successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error("Please fill form correctly.");
    }
  };

  const onEdit = (data) => {
    setEditID(data?._id);
    setFormData(data);
    setImage(data?.picture);
    setMode("edit");
    setOpen(true);
  };

  const seeDetail = async (data) => {
    const meetingsAndEvents = await getUserEventsAndMeetings(data._id);
    console.log(meetingsAndEvents, "meetingsAndEvents");

    if (meetingsAndEvents?.data?.status === 200) {
      setSponsorStats(meetingsAndEvents?.data?.data);
      console.log(meetingsAndEvents?.data?.data);
      setUserInfo(data);
      setShowSponsorStatsModal(true);
    }
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteUser({ id: data._id });
      if (res.data.status === 200) {
        message.success(res.data.message);
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      render: (picture, data) => (
        <div className="flex justify-center">
          <img
            className="rounded-full object-cover"
            src={picture || (data.gender === "male" ? user : userFemale)}
            alt=""
            style={{ height: "45px", width: "45px" }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data.first_name} ${data.last_name}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => <p className="text-center font-medium">{email}</p>,
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (password) => (
        <p className="text-center font-medium">{password}</p>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span className="mx-2 cursor-pointer" onClick={() => seeDetail(data)}>
            <img src={eye_regular} alt="" width={15} />
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => onEdit(data)}>
            <img src={edit_icon} alt="" width={15} />
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => onDelete(data)}>
            <img src={delete_icon} alt="" width={15} />
          </span>
        </span>
      ),
    },
  ];

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearch(value);
      refetch(); // Trigger API call when user stops typing
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleSearchChange(value);
  };

  return (
    <Container
      selected={"3"}
      header={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <img src={sponsors_white_icon} width={17} height={17} alt="" />
            </div>
            <p className="ml-2 font-semibold text-2xl">Sponsors</p>
          </div>
          <div className="flex items-center">
            <ThemeInput
              className="mb-1"
              placeholder="Search sponsors"
              onChange={handleInputChange}
            />

            <ThemeButton
              onClick={() => setOpen(true)}
              className={"ml-3"}
              content={
                <div className="flex items-center">
                  <img src={add_icon} alt="" width={18} height={18} />
                  <p className="ml-2">Add Sponsor</p>
                </div>
              }
            />
          </div>
        </div>
      }
    >
      <SponsorDrawer
        formData={formData}
        image={image}
        setImage={(e) => setImage(e)}
        setFormData={(e) => setFormData(e)}
        isLoading={mode === "create" ? regestiring : updating}
        onSubmit={onSubmit}
        open={open}
        onClose={onClose}
        btnTxt={mode === "create" ? "Add Sponsor" : "Update Sponsor"}
        addIcon={mode === "create"}
        mode={mode}
      />
      <ThemeTable
        pagination={{ total, setCurrentPage, setResultPerPage }}
        columns={columns}
        data={data?.users?.data || []}
      />

      <ShowStatsModal
        isModalOpen={showSponsorStatsModal}
        stats={sponsorStats}
        user={userInfo}
        onClose={() => setShowSponsorStatsModal(false)}
      />
    </Container>
  );
};

export default Sponsors;
