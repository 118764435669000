import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants/constants'
import { getCurrentToken, getValueFromStorage } from '../helpers/helpers'

export const LogApi = createApi({
  reducerPath: 'log',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      headers.set('Content-type', 'application/json')
      headers.set('authorization', `Bearer ${getCurrentToken()}`)
      return headers
    }
  }),
  endpoints: builder => ({
    getLog: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        type = '',
        log = '',
        event = '',
        status = '',
        device = ''
      }) => ({
        url: 'api/log',
        method: 'GET',
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
          type,
          log,
          event,
          status,
          device
        }
      })
    })
  })
})

export const { useLazyGetLogQuery } = LogApi
