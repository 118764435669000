import { useState, useEffect } from "react";
import { no_agenda } from "../../../../assets/images/images";
import {
  ThemeButton,
  ThemeDropdown,
  AddAgendaModal,
  PersonalAgendaPDF,
} from "../../../../components/components";
import {
  useLazyGetAgendaQuery,
  useAddAgendaMutation,
  useDeleteAgendaMutation,
} from "../../../../services/agenda";
import { getUserInfo } from "../../../../helpers/helpers";
import { useParams } from "react-router-dom";
import UserAgenda from "../eventAgenda/eventAgenda";
import options from "../../../../lib/data/options.json";
import { Spin, message } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useGetInvitationQuery } from "../../../../services/invitation";
import { useLazyGetEventByIDQuery } from "../../../../services/events";
import { format, addDays, isBefore } from "date-fns";

const PersonalAgenda = ({
  eventId,
  eventName,
  schedules,
  isOtherNetworking,
  currentAttendee,
  meetings,
  eventDate
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getAgenda, { isLoading }] = useLazyGetAgendaQuery();
  const [deleteUserAgenda, { isLoading: deleteLoading }] =
    useDeleteAgendaMutation();
  const [addUserAgenda, { isLoading: addLoading }] = useAddAgendaMutation();
  const [getEventByID, { isLoading: eventLoading }] =
    useLazyGetEventByIDQuery();

  const { data: allInvitations, isLoading: invitationLoading } =
    useGetInvitationQuery({
      eventId: eventId,
      sender: getUserInfo().id,
      receiver: getUserInfo().id,
      status: "accepted",
    });

  const [agenda, setAgenda] = useState([]);
  const [event, setEvent] = useState([]);
  const [personalSchedules, setPersonalSchedules] = useState([]);
  const [day, setDay] = useState("All");
  const [formData, setFormData] = useState([]);
  const { id } = useParams();

  const agendaData = [
    {
      time: "08:00 - 8:45",
      location: "Registration Desk",
      activity: "Registration",
    },
    {
      time: "08:45 - 8:55",
      location: "Presentation Room",
      activity: "Company & Summit Introduction",
    },
    {
      time: "08:55 - 9:00",
      location: "Presentation Room",
      activity: "Chairperson Remarks",
      description: '"Opening Speech"\nKalyan Obalampalli | Clin.AI',
    },
    {
      time: "09:00 - 09:20",
      location: "Presentation Room",
      activity: "Keynote 1",
      description:
        '"Leveraging machine learning to accelerate target engines"\nFranck Rapaport | Sanofi',
    },
    {
      time: "09:20 - 09:40",
      location: "Presentation Room",
      activity: "Keynote 2",
      description:
        '"Navigating the challenges of program management in the path towards clinic"\nHongkang Zhang, PhD | Pain Therapeutic',
    },
    {
      time: "09:40 - 10:00",
      location: "Presentation Room",
      activity: "Panel Discussion",
      topic: "Leveraging machine learning to accelerate target engines",
      speakers: [
        { name: "Franck Rapaport", company: "Sanofi", avatar: "franckAvatar" },
        {
          name: "Christin Glorioso",
          company: "NeuroAge Therapeutics",
          avatar: "christinAvatar",
        },
        {
          name: "Shengjiang Liu",
          company: "Avirmax Inc",
          avatar: "shengjiangAvatar",
        },
      ],
    },
  ];

  const networkingSessions = [
    {
      time: "10:15 - 10:40",
      location: "Pre Meeting Area",
      activity: "Meeting 1",
      tableNo: "Table no 1",
      attendees: [
        { name: "Name", company: "Company", avatar: "path_to_avatar.jpg" },
      ],
    },
    {
      time: "10:40 - 11:05",
      location: "Pre Meeting Area",
      activity: "Meeting 2",
      tableNo: "________",
      attendees: [],
    },
    {
      time: "11:05 - 11:30",
      location: "Pre Meeting Area",
      activity: "Meeting 3",
      tableNo: "Table no 3",
      attendees: [
        { name: "Name", company: "Company", avatar: "path_to_avatar.jpg" },
      ],
    },
    {
      time: "11:30 - 11:55",
      location: "Pre Meeting Area",
      activity: "Meeting 4",
      tableNo: "Table no 4",
      attendees: [
        { name: "Name", company: "Company", avatar: "path_to_avatar.jpg" },
      ],
    },
  ];

  const userInfo = {
    name: currentAttendee?.first_name + " " + currentAttendee?.last_name,
    position: currentAttendee?.metadata.delegate_details.position,
    company: currentAttendee?.metadata.delegate_details.company,
  };

  const getUserAgenda = async () => {
    const response = await getAgenda({
      event_id: id,
      user_id: getUserInfo().id,
    });

    const responseEvent = await getEventByID(eventId);

    if (responseEvent?.data.events.data[0]) {
      setEvent(responseEvent?.data.events.data[0]);
    }

    console.log("id->", id, getUserInfo().id);
    const arr = response?.data?.agendas.data || [];
    console.log("Agendas->", response?.data?.agendas.data);
    setAgenda(arr.map((v) => ({ ...v.schedule, agenda_id: v._id })));
  };

  const addAgenda = async (scheduleID) => {
    console.log("scheduleID-->", scheduleID);
    const response = await addUserAgenda({
      event: eventId,
      schedule: scheduleID,
      user: getUserInfo().id,
    });

    if (response?.data?.status === 200) {
      message.success("Schedule added successfully.");
      setIsModalOpen(false);
      getUserAgenda();
    } else {
      message.error("Something went wrong");
    }
  };

  console.log(event, "eventeventevent");
  console.log(schedules, "schedulesschedulesschedules");

  const deleteAgenda = async (sch) => {
    console.log("sch->", sch);
    const deletedAgenda = await deleteUserAgenda(sch.agenda_id);
    console.log("deletedAgenda->", deletedAgenda);
    if (deletedAgenda.data.status === 200) {
      message.success("Delete agenda successfully");
      getUserAgenda();
    } else {
      message.error("Something went wrong");
    }
    console.log("deletedAgenda resp", deletedAgenda);
  };

  const onAddAgenda = (data) => {
    setIsModalOpen(true);
    let filterAgendas = schedules.filter(
      (item) =>
        (item.start_time === data.start_time ||
          item.end_time === data.end_time) &&
        item.day === data.day
    );
    setFormData(filterAgendas || []);
  };

  useEffect(() => {
    getUserAgenda();
  }, [day]);

  useEffect(() => {
    let personalSchedule = [];
    // const data = schedules.filter((data) => data.day === day);
    const data = schedules;
    const sortedAgenda = agenda;

    // const sortedAgenda = agenda.filter((data) => data.day === day);
    for (var i = 0; i < data?.length; i++) {
      let isStartTimeThere = personalSchedule.findIndex(
        (item) =>
          item.day === data[i].day && item.start_time === data[i].start_time
      );
      if (isStartTimeThere === -1) {
        let inAgenda = sortedAgenda.find(
          (item) =>
            item.type === data[i].type && item.start_time === data[i].start_time
        );
        personalSchedule.push(inAgenda ? { ...inAgenda } : { ...data[i] });
      }
    }

    console.log(data, "sortedAgendasortedAgendasortedAgenda")

    setPersonalSchedules(personalSchedule);
  }, [schedules, agenda]);

  // Check if the current date is within one week before the event end date

  const [userType, setUserType] = useState(localStorage.getItem("userType")); // Initialize directly
  const [canDownload, setCanDownload] = useState(false);

  useEffect(() => {
    const endDate = new Date(event.end_date);
    const currentDate = new Date();
    const oneWeekBeforeEndDate = new Date(endDate);
    oneWeekBeforeEndDate.setDate(endDate.getDate() - 7);

    // Set canDownload based on the current date
    setCanDownload(currentDate >= oneWeekBeforeEndDate);
  }, [event.end_date]);

  const handleDownloadClick = () => {
    if (!canDownload) {
      message.info(
        "You can only download this agenda within one week of the event's end date."
      );
      return; // Prevent further execution if cannot download
    }
    // ... proceed with download logic
  };
  console.log(userType, "getUserType");
  console.log(agendaData, "agendaDataagendaData");

  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDownloadButton(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);


  console.log(personalSchedules, "schedules");
  return (
    <div>
      <AddAgendaModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        day={day}
        agendas={formData}
        addAgenda={(e) => addAgenda(e)}
        isLoading={false}
        schedules={schedules}
      />
      <div className="flex items-center justify-between mb-4">
        <div>
          <ThemeDropdown
            setValue={(e) => setDay(e)}
            text={day}
            items={[
              {
                label: "All",
                key: "All",
              },
              ...options.days,
            ]}
            userInfo={{
              name: "Leo Carbota",
              position: "VP of Clinical Operations",
              company: "Sanofi",
            }}
            day="DAY 1"
            date="June 8th, 2024"
          />
        </div>
        <div className="flex justify-end w-full">
          {userType === "admin" || canDownload ? (
            <PDFDownloadLink
              document={
                <PersonalAgendaPDF
                  agenda={agendaData}
                  userInfo={userInfo}
                  day="DAY 1"
                  date="June 8th, 2024"
                  networkingSessions={networkingSessions}
                  schedules={schedules}
                  meetings={meetings}
                  currentUserId={getUserInfo().id}
                  eventDate={eventDate}
                />
              }
              fileName="PersonalAgenda.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading || !showDownloadButton ? (
                  "Loading document..."
                ) : (
                  <ThemeButton content={<p>Download</p>} />
                )
              }
            </PDFDownloadLink>
          ) : (
            <button onClick={handleDownloadClick}>
              <ThemeButton content={<p>Download</p>} />
            </button>
          )}
        </div>
      </div>
      {schedules.length ? (
        day === "All" ? (
          options?.days?.map((d) => {
            return (
              <UserAgenda
                day={d?.value}
                deleteAgenda={(sch) => deleteAgenda(sch)}
                agenda={agenda || []}
                schedules={schedules}
                isPersonalize={true}
                onAddAgenda={(e) => onAddAgenda(e)}
                onDelete={deleteAgenda}
                invitations={allInvitations?.invitations?.data}
                event={event}
                id={id}
                currentUserId={getUserInfo().id}
                isOtherNetworking={isOtherNetworking}
              />
            );
          })
        ) : (
          <UserAgenda
            day={day}
            deleteAgenda={(sch) => deleteAgenda(sch)}
            agenda={agenda || []}
            schedules={schedules}
            isPersonalize={true}
            onAddAgenda={(e) => onAddAgenda(e)}
            onDelete={deleteAgenda}
            invitations={allInvitations?.invitations?.data}
            id={id}
            currentUserId={getUserInfo().id}
            isOtherNetworking={isOtherNetworking}
          />
        )
      ) : (
        <div
          className="bg-white rounded-lg mt-4 flex justify-center items-center flex-col"
          style={{ height: 450 }}
        >
          <img src={no_agenda} alt="" width={450} />
          <p className="font-bold text-4xl mt-3">
            Create Your <span className="text-primary">Agenda</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default PersonalAgenda;
