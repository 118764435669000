import { Col, Row } from "antd";
import { user } from "../../assets/images/images";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { useState } from "react";
import { ThemeButton } from "../components";
import PdfViewer from "../../pages/eventDetails/components/view-pdf";

const KeyNoteCard = ({
  keynote,
  onEdit,
  onDelete,
  isAdmin,
  isUser,
  isAdded,
  onAddAgenda,
  event,
}) => {
  console.log(keynote, "keynote");
  const [count, setCount] = useState(2);
  const [showPDF, setShowPDF] = useState(null);
  return (
    <div className="keynote-card relative">
      <div
        className={`${
          keynote?.hall === "Hall A" ? "bg-primary" : "bg-primary"
        } flex items-center justify-center py-4 px-4 static`}
      >
        <div className="flex text-white items-center">
          <p
            className={`${
              keynote?.hall === "Hall A" ? "text-[#e2b025]" : "text-[#e2b025]"
            } bg-[#fff] text-[12px]  rounded-full  py-1 px-4 font-bold mr-6`}
          >
            {keynote?.short_title}
          </p>

          <p className="text-[14px] font-semibold mt-1">{`${keynote.start_time} - ${keynote.end_time}`}</p>

          <p
            className={`${
              keynote?.hall === "Hall A" ? "text-[#e2b025]" : "text-[#e2b025]"
            } bg-[#fff] text-[12px] rounded-full  py-1 px-4 font-bold ml-6`}
          >
            {keynote?.hall}
          </p>
        </div>
        {isAdmin && !isAdded && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(keynote)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(keynote))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
        {!isAdmin && isAdded !== -1 && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(keynote.agenda_id))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
      </div>
      {(isAdmin || isAdded !== -1) && (
        <div className="min-h-[300px] max-h-auto overflow-scroll bg-white p-4 shadow-md shadow-gray-300 mb-4">
          <p
            className={`${
              keynote?.hall === "Hall A" ? "text-[#e2b025]" : "text-[#e2b025]"
            } text-[16px] font-semibold pb-1 flex`}
          >
            <Col span={1}>
              <div
                className={`${
                  keynote?.hall === "Hall A" ? "bg-primary" : "bg-primary"
                } bg-primary mt-2 rounded-full h-2 w-2`}
              ></div>
            </Col>
            <Col span={23} className="mb-3">
              <p className="font-medium">{keynote?.title}</p>
            </Col>
          </p>
          <Row className="flex items-start mt-2">
            {keynote?.description.length > 0 &&
              keynote?.description?.slice(0, count).map((data, index) => {
                return (
                  <>
                    <Col span={1}>
                      <div
                        className={`${
                          keynote?.hall === "Hall A"
                            ? "bg-primary"
                            : "bg-primary"
                        } bg-primary mt-2 rounded-full h-2 w-2`}
                      ></div>
                    </Col>
                    <Col span={23} className="mb-3">
                      <p className="font-medium">{data}</p>
                    </Col>
                  </>
                );
              })}
          </Row>
          {keynote?.description?.length > 2 && (
            <Row className="flex">
              <Col
                onClick={() => {
                  if (keynote?.description?.length === count) {
                    setCount(2);
                  } else {
                    setCount(keynote?.description.length);
                  }
                }}
                span={24}
              >
                <div
                  className={`${
                    keynote?.hall === "Hall A"
                      ? "text-primary"
                      : "text-[#e2b025]"
                  } font-bold	cursor-pointer text-right`}
                >
                  {keynote?.description?.length === count
                    ? "Show less"
                    : "Show more"}
                </div>
              </Col>
            </Row>
          )}
          <div className="mt-5 flex items-center">
            <div className="">
              <img
                width="50"
                height="50"
                src={keynote?.speaker?.picture || user}
                alt=""
                className="w-[50px] h-[50px] rounded-full object-cover mb-4"
              />
              {/* {keynote.abstract && <PdfViewer pdfUrl={keynote.abstract} />} */}
            </div>
            <div className="ml-3">
              <p
                className={`${
                  keynote?.hall === "Hall A" ? "text-primary" : "text-[#e2b025]"
                } font-extrabold text-base md:text-lg`}
              >
                {keynote?.speaker?.first_name} {keynote?.speaker?.last_name}
              </p>
              <p className="text-xs">
                {keynote?.speaker?.metadata?.delegate_details?.position ||
                  "----"}
              </p>
              <p
                className={`${
                  keynote?.hall === "Hall A" ? "text-primary" : "text-[#e2b025]"
                } text-xs font-semibold`}
              >
                {keynote?.speaker?.metadata?.delegate_details?.company ||
                  "----"}
              </p>
            </div>
          </div>
        </div>
      )}
      {isUser && isAdded === -1 && (
        <div className="min-h-[200px] max-h-auto overflow-scroll bg-white p-4 shadow-md shadow-gray-300 mb-4 flex justify-center items-center">
          <ThemeButton
            onClick={() => onAddAgenda(keynote)}
            content={
              <div>
                <PlusCircleOutlined className="mr-4" />
                <span>Add agenda</span>
              </div>
            }
            className="w-46 text-lg !h-12 mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default KeyNoteCard;
