import { Button, Empty, Modal, message } from "antd";
import {
  SecondContainer,
  ThemeButton,
  SponsorCard,
} from "../../components/components";
import { useNavigate } from "react-router-dom";
import {
  useGet_event_by_userIdQuery,
  useGetEventsQuery,
} from "../../services/events";
import { useGetInvitationQuery } from "../../services/invitation";
import {
  addKeyToStorage,
  getUserInfo,
  getValueFromStorage,
} from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useGetUsersQuery } from "../../services/auth";
import { useGetBookQuery, useLazyGetBookQuery } from "../../services/book";
import { useGetAttendeeByIdQuery } from "../../services/slots";
import { useGetMeetingsByUserIdQuery } from "../../services/newMeeting";

const UpcomingEvents = () => {
  const history = useNavigate();

  useEffect(() => {
    function handleMessage(event) {
      if (event.origin !== window.location.origin) {
        return;
      }

      const { token, userType, userId } = event.data;

      if (token && userType) {
        sessionStorage.setItem(`${userType}Token`, token);
        sessionStorage.setItem("userType", userType);
        sessionStorage.setItem("user", userId);
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const navigate = useNavigate();
  const [isMessageShow, setIsMessageShow] = useState(false);
  const [isOpenSponsorCard, setIsOpenSponsorCard] = useState(false);
  const { data: events, isLoading: eventLoading } = useGetEventsQuery({
    attendeeId: getUserInfo().id,
    currentPage: 1,
    resultPerPage: 1,
    // upcoming: true,
  });

  const { data: eventsByUserId, isLoading: eventByUserIdLoading } =
    useGet_event_by_userIdQuery(getUserInfo().id);

  // Ensure you're not directly mutating the events array
  const sortedEvents = eventsByUserId?.events?.slice().sort((a, b) => {
    const today = new Date();
    const aStartDate = new Date(a.start_date);
    const bStartDate = new Date(b.start_date);
    const aEndDate = new Date(a.end_date);
    const bEndDate = new Date(b.end_date);

    // Sort: ongoing first, then upcoming, then past
    if (aEndDate < today && bEndDate < today) return 1; // Both past, keep b first
    if (aStartDate <= today && aEndDate >= today) return -1; // a is ongoing
    if (bStartDate <= today && bEndDate >= today) return 1; // b is ongoing
    if (aStartDate > today && bStartDate > today)
      return aStartDate - bStartDate; // Both upcoming
    return -1; // Both past, keep a first
  });

  console.log("eventsByUserId", eventsByUserId);

  const { data: user, isLoading: fetchUser } = useGetUsersQuery({
    userId: getUserInfo().id,
  });

  const [getBook, { data: book, isLoading: fetchBook }] = useLazyGetBookQuery();

  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation,
  } = useGetInvitationQuery({
    eventId: events?.events?.data[0]?._id,
    sender: getUserInfo().id,
    receiver: getUserInfo().id,
  });

  console.log("invitations", invitations);

  const { data: onGoingEvent, isLoading: eventOnGoingLoading } =
    useGetEventsQuery({
      attendeeId: getUserInfo().id,
      currentPage: 1,
      resultPerPage: 1,
      ongoing: true,
    });

  const [upcomingEvent, setUpcomingEvent] = useState({});
  const [eventName, setEventName] = useState("");
  const [hasPendingMeetings, setHasPendingMeetings] = useState();
  const [atendeeId, setAteendeeId] = useState();
  const {
    data: meetingsData,
    isLoading: meetingloading,
    refetch: refetchMeeting,
  } = useGetMeetingsByUserIdQuery(
    atendeeId && { userId: atendeeId, eventId: getUserInfo().event }
  );
  useEffect(() => {
    if (events?.events?.data.length > 0) {
      setUpcomingEvent(events?.events?.data[0]);
    } else {
      setUpcomingEvent({ ...onGoingEvent?.events?.data[0] });
    }
    getBook({
      event_id:
        events?.events?.data[0]?._id || onGoingEvent?.events?.data[0]?._id,
    });
  }, [eventOnGoingLoading, eventLoading]);

  useEffect(() => {
    const message = getValueFromStorage("message");
    const invitationList = invitations?.invitations?.data || [];
    const meetingNotification = [];
    for (var i = 0; i < invitationList.length; i++) {
      const status = invitationList[i].status;
      if (
        invitationList[i].sender &&
        invitationList[i].sender._id !== getUserInfo().id
      ) {
        meetingNotification.push(invitationList[i]);
      }
    }
    if (!message) {
      const timer = setTimeout(() => {
        meetingNotification?.length > 0 && setIsMessageShow(true);
        addKeyToStorage("message", true);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [eventLoading, invitationLoading, invitations]);

  console.log(events?.events?.data[0]?.attendees, "attendees");

  const filterAteendee = upcomingEvent?.attendees?.find(
    (item) => item?.user?._id === getUserInfo().id
  );

  useEffect(() => {
    if (filterAteendee) {
      setAteendeeId(filterAteendee._id);
    }
    if (meetingsData) {
      const pendingMeetings = meetingsData?.meetings?.filter(
        (meeting) =>
          meeting?.receiver?.user?._id === getUserInfo().id &&
          meeting?.status === "pending"
      );

      console.log(pendingMeetings, "pendingMeetings");
      setHasPendingMeetings(pendingMeetings.length > 0);
    }
  }, [meetingsData, meetingloading, filterAteendee]);

  return (
    <SecondContainer>
      <SponsorCard
        open={isOpenSponsorCard}
        onClose={() => setIsOpenSponsorCard(false)}
        data={{ user: user?.users?.data[0] }}
        eventData={eventName}
      />
      <Modal centered width={400} open={isMessageShow} footer={false}>
        <p className="text-lg font-semibold text-black">
          <InfoCircleOutlined className={"text-blue-500"} /> Pending Invites
        </p>
        <p className="text-base my-2">
          Please accept the invites sent by the Sponsors
        </p>
        <div className="flex justify-end">
          <Button onClick={() => setIsMessageShow(false)} className="mx-2 h-9">
            Cancel
          </Button>
          <ThemeButton
            content={"Go"}
            onClick={() => {
              setIsMessageShow(false);
              navigate(`/upcoming-events/details/${upcomingEvent._id}?tab=4`);
            }}
          />
        </div>
      </Modal>
      <div className="bg-white rounded-xl p-5 mb-8">
        {book?.book?.pdf ? (
          <ThemeButton
            content={"Download Book"}
            className={"ml-6 md:mt-0 md:ml-6"}
            onClick={() => window.open(book?.book?.pdf, "_blank")}
          />
        ) : null}
        {sortedEvents?.length ? (
          sortedEvents.map((event) => {
            const startDate = new Date(event?.start_date);
            const endDate = new Date(event?.end_date);
            const today = new Date();

            let eventStatus;
            if (endDate < today) {
              eventStatus = "Past Event"; // Event has ended
            } else if (startDate > today) {
              eventStatus = "Upcoming Event"; // Event is in the future
            } else {
              eventStatus = "Ongoing Event"; // Event is today
            }

            if (eventStatus === "Past Event") {
              return null;
            }

            return (
              <>
                <div className="flex justify-between items-center ">
                  <p className="font-semibold text-3xl capitalize">
                    {eventStatus}
                  </p>
                  <div className="flex  items-center">
                    <ThemeButton
                      content={"Download Card"}
                      className={"ml-6 md:mt-0 md:ml-14"}
                      onClick={() => {
                        setIsOpenSponsorCard(true);
                        setEventName(event?.name);
                      }}
                    />
                  </div>
                </div>
                <div
                  key={event._id} // Add a unique key for each event
                  onClick={() => navigate(`details/${event._id}`)}
                  className={`cursor-pointer mb-10 ${
                    eventStatus === "Past Event" ? "opacity-90 bg-gray-200" : ""
                  }`} // Darken past events and add a light gray background                  }`} // Darken past events
                >
                  <div className="mt-4 relative">
                    <img
                      src={event?.banner}
                      alt=""
                      className="w-full h-[300px] object-cover rounded-xl"
                    />
                    <div className="md:block hidden w-full absolute bg-[rgba(0,0,0,0.8)] h-[300px] top-0 rounded-xl" />
                    <div className="mt-3 md:mt-0 static md:absolute top-0 md:right-24 md:px-0 flex flex-col w-full md:w-auto md:items-end justify-between md:justify-center h-full">
                      <div>
                        <p className="md:text-white w-[100%] font-bold text-lg md:text-4xl">
                          {event?.name}
                        </p>
                        <div className="flex justify-end md:mt-3">
                          <p className="md:text-white mr-5 font-medium">
                            {startDate.toDateString()}
                          </p>
                          <p className="md:text-white font-medium">
                            {endDate.toDateString()}
                          </p>
                        </div>
                        <div className="flex justify-end md:mt-3">
                          <p className="md:text-white font-medium">
                            {event?.location}
                          </p>
                        </div>
                      </div>
                      <div className="flex md:w-full justify-end mt-3 md:ml-0 md:mt-10 max-w-[500px]">
                        <ThemeButton
                          content={"See Details"}
                          className={
                            "md:!h-10 md:w-[50%] w-full ml-6 md:mt-0 md:ml-14"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="p-20">
            <Empty />
          </div>
        )}
      </div>

      <Modal
        centered
        width={400}
        open={hasPendingMeetings}
        footer={false}
        onCancel={() => setHasPendingMeetings(false)}
      >
        <p className="text-lg font-semibold text-black">
          <InfoCircleOutlined className={"text-blue-500"} /> Pending Invites
        </p>
        <p className="text-base my-2">
          Please accept the invites sent by the Sponsors
        </p>
        <div className="flex justify-end">
          <Button
            onClick={() => setHasPendingMeetings(false)}
            className="mx-2 h-9"
          >
            Cancel
          </Button>
          <ThemeButton
            content={"Go"}
            onClick={() => {
              setHasPendingMeetings(false);
              navigate(`/upcoming-events/details/${upcomingEvent._id}?tab=4`);
            }}
          />
        </div>
      </Modal>
    </SecondContainer>
  );
};

export default UpcomingEvents;
