import { useEffect, useMemo, useState } from "react";
import { Tabs, Modal, Button, message, Pagination } from "antd";
import { Spinner } from "@chakra-ui/react";
import {
  useGetMeetingsQuery,
  usePutMeetingsMutation,
  useDeleteMeetingsMutation,
} from "../../../services/newMeeting";
import { useDrag, useDrop } from "react-dnd";
import { ThemeButton } from "../../../components/components";

const { TabPane } = Tabs;

// Define the type for draggable items
const ItemType = "RECEIVER";

const SponsorMeetings = ({ eventID, attendance }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [additonalMeetings, setAdditonalMeetings] = useState([]);
  const [oldMeeting, setOldMeeting] = useState([]);
  const [swapCounter, setSwapCounter] = useState(0);
  const [currentSlotId, setCurrentSlotId] = useState("");
  const [currentSlot, setCurrentSlot] = useState({});
  const [sponsorOrder, setSponsorOrder] = useState([]);
  const [filteringAdditonalMeetings, setFilteringAdditonalMeetings] = useState(
    []
  );

  const [slotsData, setSlotsData] = useState({
    meetingType: "sponsor-to-all",
    eventId: eventID,
  });

  const {
    data,
    refetch,
    isLoading: slotFetchingLoading,
  } = useGetMeetingsQuery(slotsData);
  const [updateMeeting] = usePutMeetingsMutation();
  const [deleteMeetings] = useDeleteMeetingsMutation();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSlotsData((prevData) => ({ ...prevData, page: page }));
    refetch();
  };

  useEffect(() => {
    if (data?.meetings?.length > 0) {
      const order = [
        ...new Set(
          data.meetings
            .filter(
              (m) => !m.isAdditional && m.meetingType === slotsData.meetingType
            )
            .map((m) => m.sender?._id)
            .filter(Boolean) // This will remove any undefined or null values
        ),
      ];
      setSponsorOrder(order);
    }
  }, [data, slotsData.meetingType]);

  useEffect(() => {
    refetch();
  }, [slotsData.meetingType, refetch]);

  const meetings = data?.meetings;

  useEffect(() => {
    if (meetings?.length > 0) {
      const filteredMeetings = meetings
        .filter(
          (item) =>
            item.isAdditional === false &&
            item.meetingType === slotsData.meetingType
        )
        .sort((a, b) => {
          const dayA = parseInt(a.slot.day.split(" ")[1]);
          const dayB = parseInt(b.slot.day.split(" ")[1]);
          if (dayA !== dayB) {
            return dayA - dayB;
          }
          const timeA = new Date(`1970/01/01 ${a.slot.startTime}`);
          const timeB = new Date(`1970/01/01 ${b.slot.startTime}`);
          return timeA - timeB;
        });

      setFilteringAdditonalMeetings(filteredMeetings);
    }
  }, [meetings, slotsData.meetingType]);

  if (slotFetchingLoading) {
    return <Spinner />;
  }

  // if (!data?.meetings?.length > 0) {
  //   return null;
  // }

  console.log(filteringAdditonalMeetings, "filteringAdditonalMeetings");

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const openModal = (
    oldDeligatee,
    sender,
    meetingId,
    currentSLotId,
    currentSLot,
    sponsorIndex
  ) => {
    const filterIsAdditonalMeetings = meetings.filter(
      (item) => item.isAdditional === true && item.sender?._id === sender?._id
    );
    const filterOldMeeting = meetingId
      ? meetings.filter((item) => item?._id === meetingId)
      : [];

    if (filterIsAdditonalMeetings?.length === 0) {
      message.info("No additional meetings available for this sponsor.");
      return;
    }

    setOldMeeting(filterOldMeeting);
    setCurrentSlotId(currentSLotId);
    setCurrentSlot(
      currentSLot || {
        day: filteringAdditonalMeetings.find(
          (m) => m.slot?._id === currentSLotId
        )?.slot?.day,
      }
    );
    setAdditonalMeetings(filterIsAdditonalMeetings);

    console.log(currentSlot, "currentSlot");
    setIsModalOpen(true);
  };

  const findExistingMeeting = (id, slotId, isSender, exceptMeetingId) => {
    console.log("click", "click");
    const findMeeting = filteringAdditonalMeetings?.filter((meeting) =>
      isSender
        ? meeting.sender?._id.toString() === id.toString() &&
          (exceptMeetingId
            ? meeting._id.toString() !== exceptMeetingId.toString()
            : true)
        : meeting.receiver?._id.toString() === id.toString() &&
          (exceptMeetingId
            ? meeting._id.toString() !== exceptMeetingId.toString()
            : true)
    );

    console.log(findMeeting, "findMeeting");
    return findMeeting.find(
      (item) => item.slot?._id.toString() === slotId.toString()
    );
  };

  const findExistingMeeting2 = (id, slotId, isSender, exceptMeetingIds) => {
    console.log("click", "click");
    const findMeeting = filteringAdditonalMeetings?.filter(
      (meeting) =>
        isSender
          ? meeting.sender?._id.toString() === id.toString() &&
            (!exceptMeetingIds ||
              !exceptMeetingIds.includes(meeting._id.toString())) // Updated to check against an array
          : meeting.receiver?._id.toString() === id.toString() &&
            (!exceptMeetingIds ||
              !exceptMeetingIds.includes(meeting._id.toString())) // Updated to check against an array
    );

    console.log(findMeeting, "findMeeting");
    return findMeeting.find(
      (item) => item.slot?._id.toString() === slotId.toString()
    );
  };

  const showErrorMessage = (conflictingMeeting) => {
    const conflictingReceiver =
      conflictingMeeting.receiver?.user.first_name +
      " " +
      conflictingMeeting.receiver?.user.last_name;

    const conflictingSender =
      conflictingMeeting.sender?.user.first_name +
      " " +
      conflictingMeeting.sender?.user.last_name;

    const conflictingSlot = `${conflictingMeeting.slot.startTime} - ${conflictingMeeting.slot.endTime} (${conflictingMeeting.slot.day})`;

    const key = `error-${Date.now()}`;

    message.error({
      content: (
        <div>
          <p>
            {conflictingReceiver} already has a meeting with {conflictingSender}{" "}
            at {conflictingSlot}.
          </p>
          <ThemeButton
            size="small"
            onClick={() => message.destroy(key)}
            style={{ marginTop: "8px" }}
            content={"Dismiss"}
          />
        </div>
      ),
      duration: 8,
      key,
    });
  };

  // for repplace from addtional meeting
  const handleReplaceDelegate = async (meeting, additionalMeetingIndex) => {
    console.log(meeting, "meetingasdas");
    console.log(oldMeeting[0], "oldMeeting[0]");
    // If oldMeeting is empty, we're dealing with an empty slot
    const isEmptySlot = oldMeeting?.length === 0;

    const mapUpdatedMeeting = {
      event: isEmptySlot ? eventID : oldMeeting[0]?.event?._id,
      isAdditional: false,
      meetingType: slotsData.meetingType,
      receiver: meeting.receiver?._id,
      sender: meeting.sender?._id,
      status: "accepted",
      slot: currentSlotId,
    };

    try {
      const checkAllowedMeetings = filteringAdditonalMeetings.filter(
        (item) => item.sender?._id === meeting.sender?._id
      );

      const CheckExistingMeetingForReceiverAsSender = findExistingMeeting(
        meeting.sender?._id,
        currentSlotId,
        true, // true indicates sender
        oldMeeting[0]?._id
      );

      const CheckExistingMeetingForReceiverAsReceiver = findExistingMeeting(
        meeting.receiver?._id,
        currentSlotId,
        false,
        oldMeeting[0]?._id
      );

      const CheckExistingMeetingForDifferentSender = findExistingMeeting(
        meeting.receiver?._id,
        currentSlotId,
        true, // true indicates sender
        oldMeeting[0]?._id
      );

      const CheckExistingMeetingForDifferentReceiver = findExistingMeeting(
        meeting.sender?._id,
        currentSlotId,
        false,
        oldMeeting[0]?._id
      );

      const conflictingMeeting =
        CheckExistingMeetingForReceiverAsSender ||
        CheckExistingMeetingForReceiverAsReceiver ||
        CheckExistingMeetingForDifferentSender ||
        CheckExistingMeetingForDifferentReceiver;

      console.log(conflictingMeeting, "conflictingMeetingfoRrEPLACE");
      if (conflictingMeeting) {
        showErrorMessage(conflictingMeeting);
        return;
      }

      console.log(checkAllowedMeetings, "checkAllowedMeetings");
      let updateMeetingResult;
      if (isEmptySlot) {
        if (checkAllowedMeetings?.length >= meeting.sender.allowed_meetings) {
          message.error(
            `This ${meeting.sender?.user?.user_type} has reached their maximum allowed meetings.`
          );
          return;
        }
        // Create a new meeting for the empty slot
        updateMeetingResult = await updateMeeting({
          id: meeting?._id,
          body: mapUpdatedMeeting,
        });

        if (updateMeetingResult.data.status === 200) {
          refetch();
          handleModalClose();
        } else {
          message.error(
            isEmptySlot ? "Failed to add meeting" : "Failed to replace meeting"
          );
        }
      } else {
        // Update existing meeting
        updateMeetingResult = await updateMeeting({
          id: oldMeeting[0]?._id,
          body: mapUpdatedMeeting,
        });
      }

      if (updateMeetingResult.data.status === 200) {
        if (!oldMeeting[0]?._id) return null;

        // Update the additional meeting to mark it as used
        const updateAdditionalMeetingResult = await updateMeeting({
          id: meeting?._id,
          body: {
            ...meeting,
            receiver: oldMeeting[0]?.receiver,
            isAdditional: true,
            status: "accepted",
          },
        });

        console.log(
          updateAdditionalMeetingResult,
          "updateAdditionalMeetingResult"
        );

        if (updateAdditionalMeetingResult.data.status === 200) {
          if (!oldMeeting[0]?._id) return null;

          message.success(
            isEmptySlot
              ? "Meeting Added Successfully"
              : "Meeting Replaced Successfully"
          );
          refetch();
          handleModalClose();
        }
      } else {
        message.error(
          isEmptySlot ? "Failed to add meeting" : "Failed to replace meeting"
        );
      }
    } catch (error) {
      console.error("Error in handleReplaceDelegate:", error);
      message.error("An error occurred while updating the meeting");
    }
  };

  // Extract values for rendering
  const handleTabChange = (key) => {
    setSlotsData((prevData) => ({
      ...prevData,
      meetingType: key,
    }));

    setFilteringAdditonalMeetings([]);
  };

  // Swap meetings if dropping on an occupied slot
  const handleSwapMeetings = async (draggedId, droppedOnMeetingId) => {
    if (draggedId && droppedOnMeetingId && draggedId !== droppedOnMeetingId) {
      console.log(
        `Swapping Meeting ${draggedId} with Meeting ${droppedOnMeetingId}`
      );

      const draggedMeeting = filteringAdditonalMeetings.find(
        (item) => item?._id === draggedId
      );
      const droppedMeeting = filteringAdditonalMeetings.find(
        (item) => item?._id === droppedOnMeetingId
      );

      if (!draggedMeeting || !droppedMeeting) {
        message.error("One or both meetings not found");
        return;
      }

      // Check if swapping is within the same sender
      if (draggedMeeting.sender?._id !== droppedMeeting.sender?._id) {
        message.error(
          "Swapping is only allowed between meetings of the same sponsor"
        );
        return;
      }

      const CheckExistingMeetingForReceiverAsSender = findExistingMeeting2(
        draggedMeeting.sender?._id,
        draggedMeeting.slot?._id,
        true, // true indicates sender
        [draggedMeeting._id, droppedMeeting._id]
      );

      const CheckExistingMeetingForReceiverAsReceiver = findExistingMeeting2(
        draggedMeeting.receiver?._id,
        draggedMeeting.slot?._id,
        false,
        [draggedMeeting._id, droppedMeeting._id]
      );

      const CheckExistingMeetingForDifferentSender = findExistingMeeting2(
        draggedMeeting.receiver?._id,
        draggedMeeting.slot?._id,
        true, // true indicates sender
        [draggedMeeting._id, droppedMeeting._id]
      );

      const CheckExistingMeetingForDifferentReceiver = findExistingMeeting2(
        draggedMeeting.sender?._id,
        draggedMeeting.slot?._id,
        false,
        [draggedMeeting._id, droppedMeeting._id]
      );

      const conflictingMeetingInitial =
        CheckExistingMeetingForReceiverAsSender ||
        CheckExistingMeetingForReceiverAsReceiver ||
        CheckExistingMeetingForDifferentSender ||
        CheckExistingMeetingForDifferentReceiver;

      if (conflictingMeetingInitial) {
        showErrorMessage(conflictingMeetingInitial);
        return;
      }

      const conflictingMeeting = filteringAdditonalMeetings.find((meeting) => {
        return (
          // Check if the receiver of draggedMeeting already has another meeting at the same slot
          (meeting.slot?._id === draggedMeeting.slot?._id &&
            meeting.receiver?._id === droppedMeeting.receiver?._id &&
            meeting.sender?._id !== draggedMeeting.sender?._id) ||
          // Check if the receiver of droppedMeeting already has another meeting at the same slot
          (meeting.slot?._id === droppedMeeting.slot?._id &&
            meeting.receiver?._id === draggedMeeting.receiver?._id &&
            meeting.sender?._id !== droppedMeeting.sender?._id)
        );
      });

      if (conflictingMeeting) {
        showErrorMessage(conflictingMeeting);
        return;
      }

      const getMeetingsByDroppedSlot = filteringAdditonalMeetings.filter(
        (item) => item.slot?._id === droppedMeeting.slot?._id
      );

      console.log(getMeetingsByDroppedSlot, "getMeetingsByDroppedSlot");

      const existingMeetingInSlot = getMeetingsByDroppedSlot.find(
        (item) => item.sender?._id === draggedMeeting.receiver?._id && item.slot
      );

      console.log(existingMeetingInSlot, "existingMeetingInSlot");

      if (existingMeetingInSlot) {
        showErrorMessage(existingMeetingInSlot);
        return;
      }

      try {
        const [updateResultDragged, updateResultDropped] = await Promise.all([
          updateMeeting({
            id: draggedId,
            body: {
              ...draggedMeeting,
              receiver: droppedMeeting.receiver,
            },
          }),
          updateMeeting({
            id: droppedOnMeetingId,
            body: {
              ...droppedMeeting,
              receiver: draggedMeeting.receiver,
            },
          }),
        ]);

        if (
          updateResultDragged.data.status === 200 &&
          updateResultDropped.data.status === 200
        ) {
          message.success("Meetings swapped successfully");
          setSwapCounter((prev) => prev + 1);

          // Update the local state to reflect the changes without re-ordering
          setFilteringAdditonalMeetings((prevMeetings) =>
            prevMeetings.map((meeting) => {
              if (meeting?._id === draggedId) {
                return { ...meeting, receiver: droppedMeeting.receiver };
              }
              if (meeting?._id === droppedOnMeetingId) {
                return { ...meeting, receiver: draggedMeeting.receiver };
              }
              return meeting;
            })
          );
        } else {
          message.error("Failed to swap meetings");
        }
      } catch (error) {
        console.error("Error swapping meetings:", error);
        message.error("Error swapping meetings");
      }
    }
  };

  // Move meeting to empty slot
  const handleMoveMeeting = async (draggedId, newSlotIndex, senderId) => {
    const draggedMeeting = filteringAdditonalMeetings.find(
      (item) => item?._id === draggedId
    );

    if (!draggedMeeting) {
      message.error("Meeting not found");
      return;
    }

    // Find the new slot based on the index
    const newSlot = Object.values(
      filteringAdditonalMeetings.reduce((acc, meeting) => {
        acc[meeting.slot?._id] = meeting.slot?._id;
        return acc;
      }, {})
    )[newSlotIndex];

    const filterMatchingSlot = filteringAdditonalMeetings.find(
      (item) => item.slot?._id === newSlot
    );
    console.log(filterMatchingSlot, "newSlot");

    if (!newSlot) {
      message.error("Invalid slot");
      return;
    }

    const CheckExistingMeetingForReceiverAsSender = findExistingMeeting(
      draggedMeeting.sender?._id,
      newSlot,
      true // true indicates sender
    );

    const CheckExistingMeetingForReceiverAsReceiver = findExistingMeeting(
      draggedMeeting.receiver?._id,
      newSlot,
      false // false indicates receiver
    );

    const CheckExistingMeetingForDifferentSender = findExistingMeeting(
      draggedMeeting.receiver?._id,
      newSlot,
      true // true indicates sender
    );

    const CheckExistingMeetingForDifferentReceiver = findExistingMeeting(
      draggedMeeting.sender?._id,
      newSlot,
      false // false indicates receiver
    );

    const conflictingMeeting =
      CheckExistingMeetingForReceiverAsSender ||
      CheckExistingMeetingForReceiverAsReceiver ||
      CheckExistingMeetingForDifferentSender ||
      CheckExistingMeetingForDifferentReceiver;

    if (conflictingMeeting) {
      showErrorMessage(conflictingMeeting);
      return;
    }

    const updatedMeeting = {
      ...draggedMeeting,
      slot: newSlot,
    };

    try {
      const updateResult = await updateMeeting({
        id: draggedId,
        body: updatedMeeting,
      });
      if (updateResult.data.status === 200) {
        message.success("Meeting moved successfully");
        setSwapCounter((prev) => prev + 1); // Increment the swap counter
        refetch();
      } else {
        message.error("Failed to move meeting");
      }
    } catch (error) {
      console.error("Error moving meeting:", error);
      message.error("Error moving meeting");
    }
  };

  const ComponentTable = () => {
    // Track the ID of the meeting being dragged
    const [draggedMeetingId, setDraggedMeetingId] = useState(null);
    const [draggedOverId, setDraggedOverId] = useState(null);
    const [indexForColor, setIndexForColor] = useState(null);

    // Function to handle drag start
    const handleDragStart = (event, meetingId, index) => {
      setDraggedMeetingId(meetingId);
      event.dataTransfer.setData("text/plain", meetingId); // Store the meeting ID in the dataTransfer object
      setIndexForColor(true);
    };

    // Function to handle drop event
    const handleDrop = (event, droppedOnMeetingId, slotIndex, senderId) => {
      event.preventDefault();
      const draggedId = event.dataTransfer.getData("text/plain");

      console.log("slotIndex:", slotIndex);
      console.log("Dragged Meeting ID:", draggedId);
      console.log("Dropped On Meeting ID:", droppedOnMeetingId);
      console.log("Sender ID:", senderId);

      if (droppedOnMeetingId) {
        // Swap meetings if dropping on an occupied slot
        handleSwapMeetings(draggedId, droppedOnMeetingId);
      } else {
        // Move meeting to empty slot
        handleMoveMeeting(draggedId, slotIndex, senderId);
      }
    };

    // Function to allow dropping (necessary to prevent default behavior)
    const allowDrop = (event, meetingId) => {
      event.preventDefault();
      setDraggedOverId(meetingId);
    };

    const groupedMeetings = useMemo(() => {
      const grouped = filteringAdditonalMeetings
        .filter((meeting) => meeting?.sender && meeting?.receiver) // Filter out meetings with undefined sender or receiver
        .reduce((acc, meeting) => {
          const senderId = meeting.sender._id;
          if (!acc[senderId]) {
            acc[senderId] = {
              sender: meeting.sender,
              slots: {},
            };
          }
          acc[senderId].slots[meeting.slot._id] = {
            receiver: meeting.receiver,
            startTime: meeting.slot.startTime,
            endTime: meeting.slot.endTime,
            day: meeting.slot.day,
            meetingId: meeting._id,
          };
          return acc;
        }, {});

      // Use sponsorOrder to maintain the original order
      return sponsorOrder.map((senderId) => grouped[senderId]).filter(Boolean);
    }, [filteringAdditonalMeetings, sponsorOrder]);

    const sponsors = groupedMeetings;
    return (
      <div>
        <table className="bg-white">
          <thead>
            <tr className="p-3 my-4 flex bg-primary">
              <th className="p-3 text-white w-[181px] capitalize pt-4 relative right-11 top-1">
                {slotsData.meetingType.includes("delegate-to-all")
                  ? "Delegates"
                  : "Sponsors"}
              </th>
              {Object.values(
                filteringAdditonalMeetings.reduce((acc, meeting) => {
                  acc[
                    meeting.slot?._id
                  ] = `${meeting.slot.startTime} - ${meeting.slot.endTime} (${meeting?.slot?.day})`;
                  return acc;
                }, {})
              ).map((slotTime, index) => (
                <th key={index} className="p-3 text-white w-[161px]">
                  {slotTime}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sponsors.map((sponsorData, sponsorIndex) => (
              <tr key={sponsorIndex} className="p-3 flex">
                <td className="p-2 block w-[181px]">
                  {sponsorData?.sender?.user?.first_name}{" "}
                  {sponsorData?.sender?.user?.last_name}
                </td>

                {Object.values(
                  filteringAdditonalMeetings.reduce((acc, meeting) => {
                    acc[meeting.slot?._id] = meeting.slot?._id;
                    return acc;
                  }, {})
                ).map((slotId, slotIndex) => {
                  const meeting = filteringAdditonalMeetings.find(
                    (m) =>
                      m.slot?._id === slotId &&
                      m.sender?._id === sponsorData?.sender?._id
                  );

                  return (
                    <td
                      key={slotIndex}
                      onDragOver={(event) =>
                        allowDrop(event, sponsorData?.slots[slotId]?.meetingId)
                      }
                      onDragLeave={() => setDraggedOverId(null)}
                      onDrop={(event) => {
                        handleDrop(
                          event,
                          sponsorData?.slots[slotId]?.meetingId,
                          slotIndex,
                          sponsorData?.sender?._id
                        );
                        setDraggedOverId(null);
                      }}
                      draggable={!!sponsorData?.slots[slotId]}
                      onDragStart={(event) =>
                        handleDragStart(
                          event,
                          sponsorData?.slots[slotId]?.meetingId
                        )
                      }
                      className={`${
                        sponsorData?.slots[
                          slotId
                        ]?.receiver?.attendance?.includes(
                          sponsorData?.slots[slotId]?.day
                        )
                          ? "text-[#41f03a]"
                          : sponsorData?.slots[slotId]
                          ? "text-[#ee3535]"
                          : "bg-gray-100"
                      } cursor-pointer p-3 w-[161px] 
                     `}
                      onClick={() =>
                        openModal(
                          sponsorData?.slots[slotId]?.receiver || null,
                          sponsorData?.sender,
                          sponsorData?.slots[slotId]?.meetingId || null,
                          slotId,
                          sponsorData?.slots[slotId] || null,
                          sponsorIndex
                        )
                      }
                    >
                      {meeting
                        ? `${meeting.receiver?.user.first_name} ${meeting.receiver?.user.last_name}`
                        : "Empty"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  console.log(additonalMeetings, "additonalMeetings");

  const tabItems = [
    {
      key: "sponsor-to-all",
      label: "Sponsor To Delegate",
      children: <ComponentTable />,
    },
    {
      key: "delegate-to-all",
      label: "Delegate To Delegate",
      children: <ComponentTable />,
    },
  ];
  return (
    <>
      <Tabs items={tabItems} onChange={handleTabChange} />

      <Modal open={isModalOpen} onCancel={handleModalClose} footer={false}>
        <div className="text-sm px-2  max-h-[400px] overflow-auto">
          <div className="flex gap-4">
            <span className="mb-2">Receivers:</span>
          </div>

          {additonalMeetings?.map((meeting, index) => {
            const isAbsent = !meeting?.receiver?.attendance?.includes(
              currentSlot?.day
            );
            console.log(currentSlot, "currentSlot");

            console.log(additonalMeetings, "additonalMeetings");

            const findAllMeetings = filteringAdditonalMeetings.filter(
              (item) => item.receiver._id === meeting.receiver?._id
            );
            console.log(findAllMeetings, "currentSlot");
            console.log(findAllMeetings, "findAllMeetings");
            const isBooked = findAllMeetings.some(
              (item) => item.slot._id === currentSlotId
            );
            console.log(isBooked, "isBooked");

            return (
              <div
                key={index}
                className="flex items-center justify-between px-2 py-1 hover:bg-gray-100 cursor-pointer my-2"
                onClick={() =>
                  !isAbsent &&
                  !isBooked &&
                  handleReplaceDelegate(meeting, index)
                }
              >
                <span>
                  {meeting.receiver?.user.first_name +
                    " " +
                    meeting.receiver?.user.last_name}
                </span>
                <div>
                  {isAbsent ? (
                    <span className="ml-2 text-white p-2 rounded-md bg-red-500">
                      Absent
                    </span>
                  ) : isBooked ? (
                    <span className="ml-2 text-white p-2 rounded-md bg-orange-500">
                      Booked
                    </span>
                  ) : (
                    <span className="ml-2 text-white p-2 rounded-md bg-green-500">
                      Best Match
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default SponsorMeetings;
