import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getCurrentToken, getValueFromStorage } from "../helpers/helpers";

export const EventsApi = createApi({
  reducerPath: "events",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getCurrentToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        ongoing = "",
        upcoming = "",
        past = "",
        attendeeId = "",
      }) => {
        return {
          url: `api/event`,
          method: "GET",
          params: {
            current_page: currentPage,
            result_per_page: resultPerPage,
            ongoing: ongoing,
            upcoming: upcoming,
            past: past,
            attendee_id: attendeeId,
          },
        };
      },
    }),
    get_event_by_userId: builder.query({
      query: (userId) => {
        return {
          url: `api/event/attendee/${userId}`,
          method: "GET",
        };
      },
    }),

    getEventByID: builder.query({
      query: (id) => ({
        url: `/api/event`,
        method: "GET",
        params: {
          event_id: id,
        },
      }),
    }),

    putEvents: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/event/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    addEvents: builder.mutation({
      query: (body) => ({
        url: `api/event`,
        method: "POST",
        body: body,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `api/event/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGet_event_by_userIdQuery,
  useGetEventByIDQuery,
  usePutEventsMutation,
  useLazyGetEventsQuery,
  useLazyGetEventByIDQuery,
  useAddEventsMutation,
  useDeleteEventMutation,
} = EventsApi;
